import {
  Box,
  Button,
  Chip,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tooltipClasses,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useEffect, useState } from 'react'
import {
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useGetAllCartsQuery,
  useLazyGetAllCartsQuery,
  useLazyVerifyCartQuery,
} from '../../../redux/apis/cartApi'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { imageBaseUrl } from '../../../constants/baseUrl'
import { colors } from './styles'
import { useSelector } from 'react-redux'
import Popover from '@mui/material/Popover'
import { useSubmitPointsMutation } from '../../../redux/apis/pointsApi'
import styled from '@emotion/styled'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useLazyCouponQueryQuery } from '../../../redux/apis/couponApi'
import { appColors } from '../../../constants/colors'
const cartPrice = (total, quantity, price) => {
  const pricePlusTax = total / quantity
  const tax = pricePlusTax - price
  return tax
}

const ProductDiscount = (product) => {
  if (JSON.parse(localStorage.getItem('couponData'))) {
    const { products, couponEnter, persentage } = JSON.parse(
      localStorage.getItem('couponData')
    )
    console.log(products, product)
    if (products?.length && couponEnter !== '') {
      return products?.some((item) => item === product?.product?._id)
    }
  }
}

const calculateProductsAfterDiscount = (
  arr1,
  arr2,
  products,
  percent,
  callback
) => {
  const after1 = arr1.map((item) => {
    const { total } = item
    const itemAfterCoupon = (percent / 100) * total
    const isDiscount = products.includes(item.product._id)
    if (isDiscount) return { ...item, total: total - itemAfterCoupon }
    return item
  })
  const after2 = arr2.map((item) => {
    const { total } = item
    const itemAfterCoupon = (percent / 100) * total
    const isDiscount = products.includes(item.product._id)
    if (isDiscount) return { ...item, total: total - itemAfterCoupon }
    return item
  })

  const total = [...after1, ...after2].reduce(
    (acc, item) => item.total + acc,
    0
  )
  return callback(total)
}
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    textAlign: 'center',
    background: '#fff',
    color: '#333',
    padding: '10px',
    border: '1px solid #eee',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
})
export const calculateProductsAfterDiscountForItems = (
  productsArray,
  products,
  percent,
  callback
) => {
  let totalItemsCart = 0

  if (productsArray?.length) {
    const arr = productsArray?.map((item) => {
      const {
        total,
        product: { finalPrice },
        totalWithoutShipping,
      } = item
      const itemAfterCoupon = (percent / 100) * total
      const isDiscount = products?.includes(item.product._id)
      if (isDiscount) return { ...item, total: Math?.abs(itemAfterCoupon) }
      return item
    })

    totalItemsCart = arr?.reduce((acc, item) => item?.total + acc, 0)
  }

  return callback(totalItemsCart)
}
export const Cart04 = () => {
  const [addToCart] = useAddToCartMutation()
  const { data, isSuccess, isError, isLoading } = useGetAllCartsQuery(undefined)
  const { currencyPrice, label } = useSelector((state) => state.currency)
  const labelText = label.match(/\(([^)]+)\)/)
  const currencySymbol = labelText ? labelText[1] : ''
  const [, { language: lng }] = useTranslation()
  const [removeItem] = useDeleteFromCartMutation()
  const nav = useNavigate()
  const [fullCart, setFullCart] = useState()
  const [getCartdata, { data: cartData, isSuccess: isSuccessData }] =
    useLazyGetAllCartsQuery()
  const [shippingPrice, setShippingPrice] = useState(0)
  const [SubmitCouponA, { isError: ErrorCoupon, isLoading: isLoadingC }] =
    useLazyCouponQueryQuery()
  const [couponAdded, setCouponAdded] = useState({
    couponEnter: '',
    persentage: 0,
    products: [],
    total: 0,
  })
  const [TOTALCART, setTotal] = useState(0)
  useEffect(() => {
    isSuccess &&
      setFullCart([
        ...data?.data?.cashItems?.items,
        ...data?.data?.onlineItems?.items,
      ])
  }, [data?.data?.cashItems?.items, data?.data?.onlineItems.items, isSuccess])
  const [anchorEl, setAnchorEl] = useState(null)

  const updateQty = (method, id, qty, item) => {
    if (method === '+') {
      // setQty((prev) => prev + 1),
      console.log(data, 'sadsaddata')
      addToCart({
        quantity: qty + 1,
        properties: [],
        id: id,
        paymentType: item?.paymentType,
      })
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    } else {
      // setQty((prev) => prev - 1)
      addToCart({
        quantity: qty - 1,
        properties: [],
        id: id,
        paymentType: item?.paymentType,
      })
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    }
  }

  const currentUser = useSelector((state) => state.currentUser)

  const [coupon, setCoupon] = useState('')
  const [addPoints, { isLoading: PointsLoading, isError: PointsError }] =
    useSubmitPointsMutation()
  // const [submitCheckout, { isLoading: verfyCouponLoading }] = useLazyVerifyCartQuery()

  useEffect(() => {
    const couponData = JSON.parse(localStorage.getItem('couponData'))
    if (couponData && Object.keys(couponData).length) setCouponAdded(couponData)
  }, [])
  useEffect(() => {
    if (couponAdded.couponEnter !== '') {
      const { persentage, products } = couponAdded
      if (isSuccess && !isLoading) {
        const {
          cashItems: { items: cash },
          onlineItems: { items: online },
        } = data?.data

        calculateProductsAfterDiscount(
          online,
          cash,
          products,
          persentage,
          (total) => {
            setCouponAdded({
              ...couponAdded,
              total,
            })
          }
        )
      }
    }
  }, [data?.data, couponAdded.couponEnter])
  const handleDelete = () => {
    setCouponAdded((prev) => ({
      ...prev,
      couponEnter: '',
      persentage: 0,
      products: [],
      total: 0,
    }))
  }
  const SubmitCoupon = (e) => {
    e.preventDefault()
    if (coupon !== '') {
      SubmitCouponA(coupon)
        .unwrap()
        .then((res) => {
          setCouponAdded({
            ...couponAdded,
            couponEnter: coupon,
            persentage: res?.data?.discount,
            products: res?.data?.productsCouponsIds,
          })
          setCoupon('')

          toast.success(
            lng === 'en' ? 'coupon added succefully' : 'تم اضافه الكوبون بنجاح'
          )
        })
        .catch((e) => {
          toast.error(e?.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        })
    }
  }

  useEffect(() => {
    localStorage.setItem('couponData', JSON.stringify(couponAdded))
  }, [couponAdded.couponEnter])
  const SubmitPoints = () => {
    if (currentUser && currentUser.points) {
      addPoints(currentUser.id)
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
          getCartdata()
        })
        .catch((e) => {
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        })
    } else {
      toast.error(
        lng === 'en'
          ? `There 's No Points To Submit `
          : router`ليست هناك نقاط للاضافه بعد `
      )
    }
  }

  const HandleNavigateToCheckout = () => {
    if (
      !currentUser ||
      (typeof currentUser === 'object' && Object.keys(currentUser).length === 0)
    ) {
      toast.error(
        lng === 'en'
          ? 'You Have To Sign In First To Complete Checkout'
          : 'يجب عليك تسجيل الدخول اولا لاتمام عمليه الدفع'
      )
    } else {
      nav('/checkout')
    }
  }
  const calculateCartPrice = (online, cash) => {
    return (online + cash).toFixed(2)
  }
  const CalculatePercentage = (total, percentage) => {
    return total - (total * percentage) / 100
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const OpenAnchor = Boolean(anchorEl)
  const id = OpenAnchor ? 'simple-popover' : undefined

  useEffect(() => {
    if (isSuccess && !isError && !isLoading) {
      const shipping = [
        ...data?.data?.onlineItems?.items,
        ...data?.data?.cashItems?.items,
      ].reduce((acc, item) => {
        if (item?.product?.shippingPrice) {
          console.log(item?.product?.shippingPrice, 'TotalAfterDiscoundasd')

          return acc + item?.product?.shippingPrice * item?.quantity
        } else {
          return acc * 1
        }
      }, 0)
      const totalBeforeShipping = data?.data?.totalPrice - shipping

      if (couponAdded?.couponEnter !== '') {
        const AfterCoupon = CalculatePercentage(
          totalBeforeShipping,
          couponAdded?.persentage
        )
        console.log(AfterCoupon, 'AfterCouponAfterCoupon')
        setShippingPrice(AfterCoupon)
      } else if (shipping) {
        console.log(shipping, 'TotalAfterDiscoundasd')
        setShippingPrice(totalBeforeShipping)
      } else {
        setShippingPrice(0)
      }
      // else  if(shippingPrice>0){
      //   setShippingPrice(totalBeforeShipping)
      // }else{
      //   setShippingPrice(0)

      // }

      // setShippingPrice
    }
  }, [data, data?.data, couponAdded])
  useEffect(() => {
    if (isSuccess && !isError && !isError) {
      const TotalCartItems = data?.data?.totalPrice
      console.log(TotalCartItems, 'TotalCartsdasda')
      if (couponAdded?.couponEnter !== '') {
        const TotalAfterDiscound =
          TotalCartItems - (TotalCartItems * couponAdded?.persentage) / 100
        console.log(TotalAfterDiscound, 'TotalAfterDiscound')
        setTotal(TotalAfterDiscound)
      } else {
        setTotal(TotalCartItems)
      }

      // setShippingPrice
    }
  }, [data, data?.data, couponAdded?.couponEnter])
  return (
    <Container sx={{ overflowX: 'auto', my: 4, minHeight: '90vh' }}>
      {isLoading && <span className="loader"></span>}
      {isError && (
        <Typography
          fontSize={'1.5rem'}
          my={10}
          textAlign={'center'}
          color="error"
        >
          {lng === 'en' ? 'No items' : 'لا يوجد منتجات'}
        </Typography>
      )}
      {!isLoading && isSuccess && (
        <>
          <Box
            sx={{
              overflow: 'auto',
              width: {
                xs: '100%',
              },
            }}
          >
            <Table sx={{ minWidth: 650, my: 5 }}>
              <TableHead
                sx={{
                  border: '1px solid lightgrey',
                  bgcolor: '#FBFBFB',
                }}
              >
                <TableRow>
                  <TableCell sx={{ color: colors.header }}>
                    {lng === 'en' ? 'Product' : 'المنتجات'}
                  </TableCell>
                  <TableCell sx={{ color: colors.header }}>
                    {lng === 'en' ? 'Price' : 'السعر'}
                  </TableCell>
                  <TableCell sx={{ color: colors.header }}>
                    {lng === 'en' ? 'Quantity' : 'الكمية'}
                  </TableCell>
                  <TableCell sx={{ color: colors.header, fontSize: '12px' }}>
                    {lng === 'en' ? 'Total Price' : 'إجمالي السعر'}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ border: '1px solid lightgrey' }}>
                {isSuccess &&
                  fullCart?.map((item) => (
                    <TableRow
                      sx={{ borderBottom: '1px solid black' }}
                      key={item?.product?.title_en}
                    >
                      <TableCell>
                        <Stack sx={{ margin: '10px 0px' }}>
                          {console.log(
                            couponAdded?.persentage,
                            'couponAdded?.persentagecouponAdded?.persentage'
                          )}

                          {ProductDiscount(item) &&
                          couponAdded.couponEnter !== '' ? (
                            <Stack
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                padding: '3px 7px',
                                background: appColors?.secondary?.main,
                                borderRadius: '10px',
                                fontSize: ' 13px',
                                color: ' #fff',
                                width: 'fit-content',
                              }}
                            >
                              <Stack
                                component={'span'}
                                sx={{ margin: '0px 10px' }}
                              >
                                {lng === 'en' ? 'discount' : 'نسبه الخصم'}
                              </Stack>
                              <Stack component={'span'}>
                                {couponAdded?.persentage}%
                              </Stack>
                            </Stack>
                          ) : null}
                        </Stack>
                        <Stack
                          direction={'row'}
                          sx={{ gap: 5, alignItems: 'center' }}
                        >
                          <CloseRoundedIcon
                            sx={{
                              color: colors.remove,
                              border: `1px solid ${colors.remove}`,
                              borderRadius: 50,
                              cursor: 'pointer',
                              fontSize: '1rem',
                            }}
                            onClick={() =>
                              removeItem(item.product.id)
                                .unwrap()
                                .then((res) => {
                                  toast.success(
                                    res[`success_${lng === 'en' ? 'en' : 'ar'}`]
                                  )
                                  if (fullCart.length === 1) {
                                    localStorage.removeItem('couponData')
                                  }
                                })
                                .catch((e) =>
                                  toast.error(
                                    e.data[
                                      `error_${lng === 'en' ? 'en' : 'ar'}`
                                    ]
                                  )
                                )
                            }
                          />
                          <Box
                            sx={{
                              width: '75px',
                              height: '75px',
                              background: `url("${
                                imageBaseUrl + item.product.images[0]
                              }") center center`,
                              backgroundSize: '100%',
                              backgroundRepeat: 'no-repeat',
                            }}
                          />
                          <Typography
                            sx={{
                              color: colors.title,
                              fontSize: { xs: '16px', md: '22px' },
                            }}
                          >
                            {
                              item.product[
                                `title_${lng === 'en' ? 'en' : 'ar'}`
                              ]
                            }
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ color: colors.priceBefore }}>
                        <Typography>
                          <Box
                            sx={{
                              color: colors.priceAfter,
                              fontSize: { xs: '16px', md: '22px' },
                              fontWeight: 'bold',
                            }}
                          >
                            {console.log(
                              ProductDiscount(item) ? 55 : 0,
                              'itemitem'
                            )}
                            <Box component={'span'} sx={{ mx: 0.5 }}>
                              {currencySymbol}
                            </Box>

                            {(couponAdded?.couponEnter !== '' &&
                            ProductDiscount(item)
                              ? item?.total -
                                (item?.total * couponAdded.persentage) / 100
                              : item?.total) * currencyPrice}
                            {/* {item.product.priceAfterDiscount > 0
                              ? (
                                  (cartPrice(
                                    item.total,
                                    item.quantity,
                                    item.product.finalPrice
                                  ) +
                                    item?.product?.finalPrice*item?.product?.quantity) *
                                    currencyPrice -
                                  (ProductDiscount(item)
                                    ? (item?.total * couponAdded.persentage) /
                                      100
                                    : 0)
                                ).toFixed(2)
                              : (
                                  (cartPrice(
                                    item.total,
                                    item.quantity,
                                    item.product.finalPrice
                                  ) +
                                    item.product.priceBeforeDiscount) *
                                    currencyPrice -
                                  (ProductDiscount(item)
                                    ? (item?.total * couponAdded.persentage) /
                                      100
                                    : 0)
                                ).toFixed(2)
                                } */}
                          </Box>
                          {item.product.priceAfterDiscount > 0 && (
                            <Box
                              component={'span'}
                              sx={{
                                color: colors.priceBefore,

                                textDecoration: `line-through 1px solid `,
                                fontSize:
                                  item.product.priceAfterDiscount > 0
                                    ? '0.7rem'
                                    : '1.5rem',
                              }}
                            >
                              {` ${currencySymbol} ${(
                                (cartPrice(
                                  item.total,
                                  item.quantity,
                                  item.product.finalPrice
                                ) +
                                  item.product.priceBeforeDiscount) *
                                currencyPrice
                              ).toFixed(2)}`}
                            </Box>
                          )}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Stack
                          direction={'row'}
                          sx={{
                            alignItems: 'center',
                            p: 0,
                          }}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() =>
                              updateQty(
                                '-',
                                item.product._id,
                                item.quantity,
                                item
                              )
                            }
                            sx={{
                              color: 'black',
                              minWidth: { xs: 20, md: 30 },
                              border: '1px solid lightgrey',
                            }}
                          >
                            -
                          </Button>
                          <Typography sx={{ mx: 2 }}>
                            {item.quantity}
                          </Typography>
                          <Button
                            size=""
                            onClick={() =>
                              updateQty(
                                '+',
                                item.product._id,
                                item.quantity,
                                item
                              )
                            }
                            sx={{
                              color: 'black !important',
                              minWidth: { xs: 20, md: 30 },

                              border: '1px solid lightgrey',
                            }}
                          >
                            +
                          </Button>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ color: colors.priceBefore }}>
                        {currencySymbol}{' '}
                        {(couponAdded?.couponEnter !== '' &&
                        ProductDiscount(item)
                          ? item?.total -
                            (item?.total * couponAdded.persentage) / 100
                          : item?.total) * currencyPrice}
                        {/* {(item.total * currencyPrice).toFixed(2)} */}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Table sx={{ minWidth: 650, my: 5 }}>
              {/* <TableHead sx={{
              
            }}>
              <TableRow sx={{ border: '1px solid lightgrey' }}>
                <TableCell sx={{ py: 5 }}>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: colors.orderSummary,
                      textAlign:'right'
                    }}
                  >
                    {lng === 'en' ? 'Cart Totals' : 'إجمالي المبلغ'}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead> */}
              {lng === 'en' ? (
                <TableBody
                  sx={{
                    border: '1px solid lightgrey',
                  }}
                >
                  <TableRow sx={{}}>
                    {data?.data?.cashItems.totalPrice > 0 && (
                      <>
                        <TableCell
                          sx={{
                            color: colors.orderSummaryBody,
                            textAlign: lng === 'en' ? 'left' : 'right',
                          }}
                        >
                          {lng === 'en'
                            ? 'Cash on delivery'
                            : 'الدفع عند الإستلام'}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: colors.orderSummaryBody,
                            textAlign: ' right',
                          }}
                        >
                          {currencySymbol}{' '}
                          {/*                           
                          {
                            (  couponAdded?.couponEnter!==""&&ProductDiscount(item)?
                              item?.total-(item?.total * couponAdded.persentage)/100
                              
                              :item?.total)*currencyPrice
                            } */}
                          {couponAdded?.couponEnter !== ''
                            ? calculateProductsAfterDiscountForItems(
                                data?.data?.cashItems?.items,
                                couponAdded?.products,
                                couponAdded?.persentage,
                                (total) => total
                              ) * currencyPrice
                            : data?.data?.cashItems.totalPrice * currencyPrice}
                          {/* {(
                            data?.data?.cashItems.totalPrice * currencyPrice
                          ).toFixed(2)} */}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow>
                    {data?.data?.onlineItems.totalPrice > 0 && (
                      <>
                        <TableCell
                          sx={{
                            color: colors.orderSummaryBody,
                            textAlign: lng === 'en' ? 'left' : 'right',
                          }}
                        >
                          {lng === 'en' ? 'Online payment' : 'الدفع اونلاين'}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: colors.orderSummaryBody,
                            textAlign: ' right',
                          }}
                        >
                          {currencySymbol}{' '}
                          {/* {(
                            data?.data?.onlineItems.totalPrice * currencyPrice
                          ).toFixed(2)} */}
                          {console.log(data?.data?.onlineItems, ' onlineItems')}
                          {couponAdded?.couponEnter !== ''
                            ? calculateProductsAfterDiscountForItems(
                                data?.data?.onlineItems?.items,
                                couponAdded?.products,
                                couponAdded?.persentage,
                                (total) => total
                              ) * currencyPrice
                            : data?.data?.onlineItems.totalPrice *
                              currencyPrice}
                        </TableCell>
                      </>
                    )}
                  </TableRow>

                  {/* {shippingPrice > 0 ? (
                    <TableRow>
                      <TableCell
                        sx={{
                          color: colors.orderSummaryBody,
                          fontWeight: 'bold',
                          textAlign: ' left',
                        }}
                      >
                        {shippingPrice > 0 ? (
                          <>
                            {(
                              shippingPrice *
                              currencyPrice *
                              currencyPrice
                            ).toFixed(2)}{' '}
                            {`${currencySymbol}`}
                          </>
                        ) : lng === 'en' ? (
                          'Free'
                        ) : (
                          'مجانا'
                        )}
                      </TableCell>

                      <TableCell
                        sx={{
                          color: colors.orderSummaryBody,
                          fontWeight: 'bold',
                          textAlign: 'right',
                        }}
                      >
                        {lng === 'en' ? 'Sub Total' : 'المجموع الفرعي'}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )} */}
                  <TableRow>
                    <TableCell
                      sx={{
                        color: colors.orderSummaryBody,
                        fontWeight: 'bold',
                        textAlign: lng === 'en' ? 'left' : 'right',
                      }}
                    >
                      {lng === 'en' ? 'Total' : 'الإجمالي'}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: colors.orderSummaryBody,
                        fontWeight: 'bold',
                        textAlign: ' right',
                      }}
                    >
                      {(TOTALCART * currencyPrice).toFixed(2)}
                      {currencySymbol}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody
                  sx={{
                    border: '1px solid lightgrey',
                  }}
                >
                  <TableRow
                    sx={{
                      direction: lng === 'en' ? 'ltr' : 'rtl',
                    }}
                  >
                    {data?.data?.cashItems.totalPrice > 0 && (
                      <>
                        <TableCell sx={{ color: colors.orderSummaryBody }}>
                          {currencySymbol}{' '}
                          {(
                            data?.data?.cashItems.totalPrice * currencyPrice
                          ).toFixed(2)}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: colors.orderSummaryBody,
                            textAlign: lng === 'en' ? 'left' : 'right',
                          }}
                        >
                          {lng === 'en'
                            ? 'Cash on delivery'
                            : 'الدفع عند الإستلام'}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                  <TableRow>
                    {data?.data?.onlineItems.totalPrice > 0 && (
                      <>
                        <TableCell sx={{ color: colors.orderSummaryBody }}>
                          {currencySymbol}{' '}
                          {(
                            data?.data?.onlineItems.totalPrice * currencyPrice
                          ).toFixed(2)}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: colors.orderSummaryBody,
                            textAlign: lng === 'en' ? 'left' : 'right',
                          }}
                        >
                          {lng === 'en' ? 'Online payment' : 'الدفع اونلاين'}
                        </TableCell>
                      </>
                    )}
                  </TableRow>

                  {/* {shippingPrice > 0 ? (
                    <TableRow>
                      <TableCell
                        sx={{
                          color: colors.orderSummaryBody,
                          fontWeight: 'bold',
                          textAlign: ' left',
                        }}
                      >
                        {shippingPrice > 0 ? (
                          <>
                            {(
                              shippingPrice *
                              currencyPrice *
                              currencyPrice
                            ).toFixed(2)}{' '}
                            {`${currencySymbol}`}
                          </>
                        ) : lng === 'en' ? (
                          'Free'
                        ) : (
                          'مجانا'
                        )}
                      </TableCell>

                      <TableCell
                        sx={{
                          color: colors.orderSummaryBody,
                          fontWeight: 'bold',
                          textAlign: 'right',
                        }}
                      >
                        {lng === 'en' ? 'Sub Total' : 'المجموع الفرعي'}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )} */}

                  <TableRow>
                    <TableCell
                      sx={{
                        color: colors.orderSummaryBody,
                        fontWeight: 'bold',
                      }}
                    >
                      {(TOTALCART * currencyPrice).toFixed(2)}
                      {currencySymbol}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: colors.orderSummaryBody,
                        fontWeight: 'bold',
                        textAlign: lng === 'en' ? 'left' : 'right',
                      }}
                    >
                      {lng === 'en' ? 'Total' : 'الإجمالي'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Box>
          <Stack
            direction={'row'}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
          >
            <Box
              sx={{
                my: 2,
                width: { xs: '90%', md: '31%', lg: '31%', xl: '31%' },
                px: { sm: '5px' },
              }}
            >
              {couponAdded.couponEnter !== '' ? (
                <Chip
                  label={couponAdded.couponEnter}
                  sx={{
                    padding: '10px',
                    background: colors?.buttonBg,
                    margin: '10px auto',
                    color: '#fff',
                    borderRadius: '0px !important',
                    '& > .MuiSvgIcon-root': {
                      color: '#fff !important',
                    },
                  }}
                  onDelete={handleDelete}
                  disabled={data?.data?.couponUsed}
                />
              ) : null}
              <Box>
                <Box>
                  <Box>
                    <form onSubmit={(e) => SubmitCoupon(e)} style={{}}>
                      <Box
                        sx={{
                          display:'flex',
                          width:'100%',
                          my:'10px',
                          justifyContent:'space-between'
                        }}
                      >
                        <input
                          value={coupon}
                          // className="couponInput"
                          style={{
                            padding:'8px',
                            width:'68%'
                          }}
                          placeholder={
                            lng === 'en' ? 'discount code' : 'كود الخصم'
                          }
                          onChange={(e) => setCoupon(e.target.value)}
                        ></input>
                        <CustomWidthTooltip
                          title={
                            data?.data?.isPointUsed
                              ? lng === 'en'
                                ? "You can't use coupons because you have already used Your Points "
                                : ' لا يمكنك استخدام كوبون لانك استخدمت نقاطك بالفعل '
                              : ''
                          }
                          sx={{
                            background: '#fff !important',
                          }}
                        >
                             <Button
                              sx={{
                                border: '1px solid #ddd',
                                padding: { xs: '5px', sm: '10px' },
 
                                background: colors?.buttonBg,
                                color: `${colors?.button} !important`,
                                transition: '0.7s all ease-in-out',
                                fontSize: { xs: '10px', sm: '12px' },
                                '&:hover': {
                                  background: colors?.buttonBg,
                                  opacity: '0.7',
                                },
                                margin: { xs: 0 },
                                borderRadius: 0,
                                width:'30%'
                                // width:'200px',
                              }}
                              disabled={
                                data?.data?.couponUsed ||
                                data?.data?.isPointUsed ||
                                isLoadingC
                              }
                              type={'submit'}
                            >
                              {lng === 'en' ? 'submit' : 'تاكيد'}
                            </Button>
                          
                        </CustomWidthTooltip>
                      </Box>
                    </form>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {data?.data?.couponUsed ? (
                      <>
                        {lng === 'en'
                          ? 'You Already Have Used coupon For this cart '
                          : 'تم  استخدام الكوبون في هذه العربه'}
                      </>
                    ) : null}
                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        flexDirection: lng === 'en' ? 'row-reverse' : 'row',
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                        }}
                      >
                        {currentUser?.points || 0}
                        <HelpOutlineIcon
                          sx={{
                            width: '20px',
                            height: '20px',
                            marginRight: '10px',
                            cursor: 'pointer',
                          }}
                          aria-describedby={id}
                          type="button"
                          onClick={handleClick}
                        />
                      </span>
                      {lng === 'en' ? 'Your Points' : 'النقاط'}
                    </Typography>

                    <CustomWidthTooltip
                      title={
                        data?.data?.couponUsed ||
                        couponAdded?.couponEnter !== ''
                          ? lng === 'en'
                            ? 'You Have To Delete the coupon To Use Your Points'
                            : 'يجب حذف الكوبون حتي تتمكن من استخدام نقاطك'
                          : ''
                      }
                      sx={{
                        background: '#fff !important',
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        {data?.data?.isPointUsed ? null : (
                          <Button
                            disabled={
                              data?.data?.couponUsed ||
                              couponAdded?.couponEnter !== '' ||
                              data?.data?.isPointUsed
                            }
                            sx={{
                              m: 1,
                              border: '1px solid #ddd',
                              padding: '10px',
                              height: '40px',
                              margin: '10px auto',
                              background: colors?.buttonBg,
                              color: `${colors?.button} !important`,
                              transition: '0.7s all ease-in-out',
                              width: '100%',
                              borderRadius: 0,

                              '&:hover': {
                                background: colors?.buttonBg,
                                opacity: '0.7',
                              },
                            }}
                            onClick={SubmitPoints}
                          >
                            {lng === 'en'
                              ? 'Use Your  Points '
                              : 'استخدم نقاطك'}
                          </Button>
                        )}
                      </div>
                    </CustomWidthTooltip>

                    <Popover
                      id={id}
                      open={OpenAnchor}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      disableScrollLock
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        padding: '3px',
                      }}
                    >
                      <Typography
                        sx={{
                          p: 2,
                          textTransform: 'capitalize',
                          fontSize: {
                            xs: '13px',
                            width: 'fit-content',
                          },
                        }}
                      >
                        {lng === 'en' ? (
                          <>
                            {' '}
                            Those Points Are calculated in checkout for every
                            succefully purchesed products{' '}
                          </>
                        ) : (
                          <>هذه النقاط يتم احتسابها لكل عمليه شراء ناجحه</>
                        )}
                      </Typography>
                    </Popover>

                    <Stack
                      component={'span'}
                      sx={{
                        margin: '3px',
                        textAlign: 'right',
                        color: '#000',
                        fontWeight: '',
                      }}
                    >
                      {data?.data?.isPointUsed ? (
                        <>
                          {lng === 'en'
                            ? 'Your Points Have been Used before '
                            : 'لقد استخدمت نقاطك من قبل '}
                        </>
                      ) : null}
                    </Stack>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  margin: '10px 0px',
                  flexDirection: lng === 'en' ? 'row-reverse' : 'row',
                }}
              >
                <span>
                  {console.log(cartData, 'cartDatacartData')}
                  {TOTALCART.toFixed(2)}
                  {currencySymbol}
                </span>
                {lng === 'en' ? 'Total price' : 'المجموع  الكلي'}
              </Box>
              {/* <Typography
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          flexDirection:'row-reverse  ',
                          margin:'10px 0px'
                        }}
                      >
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          {15}
                        
                        </span>
                        {lng === 'en' ? 'Shipping Price' : 'رسوم الشحن'}
                      </Typography>
                <Typography
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          flexDirection:'row-reverse  ',
                          margin:'10px 0px'
                        }}
                      >
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          {15}
                        
                        </span>
                        {lng === 'en' ? 'Total price' : 'المجموع الكلي'}
                      </Typography> */}
              <Button
                variant="contained"
                disabled={isError && !isSuccess}
                sx={{
                  color:
                    isError && !isSuccess ? 'gray !important' : colors.button,
                  bgcolor: `${colors.buttonBg} !important`,
                  width: '100%',
                  borderRadius: 0,
                }}
                onClick={() => HandleNavigateToCheckout()}
              >
                {lng === 'en' ? 'Confirm payment' : 'تأكيد الدفع'}
              </Button>
            </Box>
          </Stack>

          {/*        
          <Button
            variant="contained"
            disabled={isError}
            sx={{
              width: '100%',
              py: 1.8,
              color: colors.button,
              bgcolor: `${colors.buttonBg} !important`,
              position: 'sticky',
              left: '0px'
            }}
            onClick={() => {
              HandleNavigateToCheckout()
            }}
          >
            {lng === 'en' ? 'Checkout' : 'الدفع'}
          </Button> */}
        </>
      )}
    </Container>
  )
}
