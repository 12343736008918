import { CardColors } from './card.colors'

const { colors } = CardColors
export default {
  cardPaper: {
    width: '100%',
    height: '100%',
    display: 'block',
    flexDirection: 'column',
    // border: `2px solid ${colors.borderColor} `,
    borderRadius: null,
    bgcolor: '#fff',
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
    '&:hover .contentContainer': {
      display: 'block',
    },
    // pb: 4,
  },
  cardImg: {
    width: '100%',
    objectFit: 'cover',
    height: '100%',
    objectPosition: 'center top'
  },
  contentContainer: {
    margin:'auto',
    display: 'none',
    color: colors.titleColor,
    background: '#ffffff7a',
    position: 'absolute',
    bottom: 3,
    width: '100%',
    p: { xs: 0.5, md: 2 },
    overflow: 'hidden',
    // px: 2,
    gap: { xs: 0.8, md: 1 },
    boxSizing: 'border-box',
    height: 'fit-content',
    right:'50%',
    transform:'translateX(50%)'
  },
  titleStyle: {
    color: colors.titleColor,
    fontWeight: { xs: '400', md: 'bold' },
    fontSize: { xs: '12px', sm: '14px', md: '1rem' },
    textAlign: 'center',
    px: { xs: 0, sm: 0 },
    'text-shadow':' 8 8 black !important' ,
    fontWeight:'bold'


    // pt: 2,
  },
  descStyle: {
    fontSize: '15px !important',
    fontWeight: 'normal',
    color: colors.descColor,
    wordBreak: 'break-word',
    display: { xs: 'none', md: 'block' },
    
  },
  Button: {
    bgcolor: `${colors.buttonBackgroundColor} !important`,
     color: colors.buttonTextColor,
    px: 1,
    width:   'fit-content' ,
    alignSelf: 'center',
    textTransform: 'capitalize',
    padding:'19px 20px',
    margin:'auto',
    borderRadius:'14px',
    fontSize:{
      xs:'15px',
      md:'20px'
    },
    height:{
      xs:'30px',
      md:'50px'
    },
    fontWeight:'500 !Important'
  },
  "&:hover":{
    background:"#fff !important",
    color:"#333 !important"
  }
}
