import { Box, Grid, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useGetSectionByTypeQuery } from '../../../../redux/apis/sectionsApi'
import { colors } from './styles'
import styles from './styles'
import { useNavigate } from 'react-router-dom'
import VideoPlayer from './VideoPlayer'
const ErrorSection = ({ error, lang }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <Typography
        fontSize={'1.5rem'}
        my={10}
        textAlign={'center'}
        color="error"
      >
        {error?.data && error?.data[`error_${lang}`]}
      </Typography>
    </Box>
  )
}
const AboutHeader = ({ title, desc, lang }) => {
  const navigate = useNavigate()
  return (
    <Box
      width={0.9}
      mx={'auto'}
      sx={{
        textAlign: { xs: 'center', md: 'initial' },
        alignItems:'flex-start',
        display:'flex',
        flexDirection:'column',
      }}
    >
      {/* Main Title */}
      <Typography variant={'h3'}  sx={{
        color:colors?.titleColor,
        textAlign:  lang=="en"?"left":'right'

      }} >
        {title}
      </Typography>

      {/* subTitle */}
      <Box
        variant="h6"
        sx={{...styles.aboutSection.dangerously,
          fontWeight:'500 !important',
          textAlign:  lang=="en"?"left":'right'

        }}
        // i need to make it end "..."
        dangerouslySetInnerHTML={{
          __html: desc ? `${desc.slice(0, 400)}....` : '',
        }}
      />
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Button
           onClick={() => {
            navigate('/aboutUs')
          }}
          sx={{
            mt: 2,
            color: colors.buttonTextColor,
            backgroundColor: `${colors.buttonBgColor} !important`,
            p: '10px 50px',
            borderRadius: '6px',
            padding:{
              xs:'10px 70px',
              md:'10px 70px'
            },
            border:'0px',
            fontSize:{
              xs:'18px',
              xl:"25px"
            },
            height:{
              xs:'50px',
              md:'50px'
            },
            fontWeight:'500 !important'

          }}
        >
          {lang === 'ar' ? 'المزيد' : 'More'}
        </Button>
      </Box>
    </Box>
  )
}
const AboutVideoButton = () => {
  const [, { language: lang }] = useTranslation()
  const { data, isSuccess, isError, error, isLoading } =
    useGetSectionByTypeQuery('aboutus')
  const desc = data?.data[0][`description_${lang}`]
  const title = data?.data[0][`title_${lang}`]
  return (
    <Box  >
      
      <Box bgcolor={colors.bgColor} sx={{
      }}>
        {isLoading && <span className="loader"></span>}
        {isError && <ErrorSection error={error} />}
        {!isLoading && !isError && isSuccess && (
          <>
          <Typography align="center" variant={'h3'}   my={'10px'} 
      
      
      sx={{
        textTransform:'capitalize',
        fontWeight:'600 !important',
        fontSize:{
          xs:"20px",
          xl:'4rem'
        },
        my:0,
        color:'#fff',
        py:3
      }}
    >
     
     
  {
    lang==="en"?"About the clinic":"عن العياده"
  }

    </Typography>
            <Grid
              container
              width={'100%'}
              sx={{
                ...styles.gridContainer,
                direction: lang === 'ar' ? 'rtl' : 'ltr',
                
              }}
            >
              {/* Description and title */}
              <Grid
                item
                xs={12}
                md={6}
                p={2}
                sx={{
                  ...styles.aboutSection.flexHeader,
                  order:{
                    xs:1,
                    md:'unset'
                  }
                }}
              >
                <AboutHeader title={title} desc={desc} lang={lang} />
              </Grid>

              {/* video */}
              <Grid item xs={12} md={5.5} p={2}>
                <VideoPlayer />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  )
}
export default AboutVideoButton
