export const ContactNoon7Style = {
  color: {
    borderColor: '#000',
    textColor: '#000',
    linkColor: '#fff',
  },
  button: {
    color: 'white',
    backgroundColor: '#1DCAD3',
  },
  iconColors: {
    color: '#fff',
  },

  background: '#1DCAD3',
  connectionsDataColor: '#fff',
  borderRadiusInput: '40px',
}
