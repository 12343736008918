import { CardMedia, Paper, Box, Stack, Typography } from '@mui/material'
import { imageBaseUrl } from '../../../../constants/baseUrl'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './card.styles'

const CardContent = ({ data, lng }) => {
  return (
    <Stack
      sx={{
        ...styles.contentContainer,
      }}
    >
      <Box
        sx={{
          marginRight: '10px',
        }}
      >
        <Typography
          sx={{
            px: lng === 'en' ? '10px' : '0',
            color: '#A3A3A3',
            textAlign: `center !important`,
            fontSize: {
              xs: '16px',
              md: '12px',
              lg: '19px',
            },
            fontStyle: 'normal !important',
          }}
        >
          {data.title.slice(0, 40) + '...'}
        </Typography>
        <Box
          className="desc"
          sx={{
            margin: '0px !Important',
            color: '#A3A3A3',
            '& ul': {
              textAlign: `center !important`,
              margin: '0px !Important',
              textAlign: 'center',
            },
            '& ol': {
              textAlign: `center !important`,
              margin: '0px !Important',
            },
            '& > p': {
              fontSize: {
                xs: '20px',
                margin: '0px !Important',
              },
              textAlign: 'center',
            },
            '& > *': {
              fontSize: {
                xs: '10px',
                md: '12px',
                lg: '1.5rem',
              },
              px: lng === 'en' ? '10px' : '0',
              textAlign: 'center',
            },
            height: 'fit-content',
          }}
          dangerouslySetInnerHTML={{
            __html:
              data.description?.split(' ').length > 3
                ? `${data.description.slice(0, 50)?.split(' ').join(' ')} ...`
                : data.description.slice(0, 50),
          }}
        />
      </Box>
    </Stack>
  )
}

//⭐ Parent
const ScrollingCardBlog = (props) => {
  const [, { language: lng }] = useTranslation()
  const nav = useNavigate()
  return (
    <Paper
      sx={{
        ...styles.cardPaper,
        direction: `${lng === 'en' ? 'ltr' : 'rtl'}`,
        padding: '0px',
      }}
    >
      {props?.data?.image && (
        <CardMedia
          component={'img'}
          src={imageBaseUrl + props?.data?.image}
          onClick={() =>
            nav(
              `/blogs/${props?.data?._id}/${props?.data?.title.replace(
                /\s/g,
                '-'
              )}`
            )
          }
          sx={styles.cardImg}
        />
      )}

      <CardContent data={props?.data} lng={lng} />
    </Paper>
  )
}

export default ScrollingCardBlog
