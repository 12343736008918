export const CardColors = {
  colors: {
    titleColor: '#333',
    backgroundColor: 'transparent',
    descColor: '#333',
    borderColor: null,
    buttonTextColor: '#fff',
    buttonBorderColor: '#363636',
    buttonBackgroundColor: '#D50154',
    hoverColor: '#fff',
  },
}
