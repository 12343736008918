import './index.css'
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Container,
  Divider,
  Grid,
  Rating,
  Stack,
  Typography,
} from '@mui/material'
import 'swiper/css'
import { Pagination } from 'swiper/modules'
import 'swiper/css/pagination'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ShishiColors } from './colors.jsx'
import { Swiper, SwiperSlide } from 'swiper/react'
import { toast } from 'react-toastify'
import 'react-medium-image-zoom/dist/styles.css'
import Zoom from 'react-medium-image-zoom'
import FavoriteIcon from '@mui/icons-material/Favorite'
import CircularProgress from '@mui/material/CircularProgress'

import {
  useAddRatingMutation,
  useGetSingleProductQuery,
} from '../../../redux/apis/ProductApis.js'
import {
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useGetAllCartsQuery,
} from '../../../redux/apis/cartApi.js'
import 'swiper/css'
import { imageBaseUrl } from '../../../constants/baseUrl.js'
import Popover from '@mui/material/Popover'
import Similarproduct from '../similarproduct/index.jsx'
import ProductComments from '../../../components/productComments/ProductComments.jsx'
import GramSvg from './gramSvg.svg'
import { CustomZoomContent } from './CustomZoom.jsx'
// =========================================popover===================================
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {
  useAddToSavedProductMutation,
  useGetAllSavedProductsQuery,
} from '../../../redux/apis/SavedProductApi.js'
import ProductReviews from '../../../components/ReviewComponent/index.jsx'

import Taby from './paymentImages/tabby.svg'
import tamara from './paymentImages/tamara.svg'
import visa from './paymentImages/visa.svg'

function MouseOverPopover({
  children,
  text,
  cartData,
  setCartData,
  key,
  attr,
  values,
}) {
  console.log(cartData)
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setCartData((prev) => {
      const newQualities = prev?.qualities?.filter(
        (v) => v.key_en !== attr.key_en && v.key_ar !== attr.key_ar
      )
      return {
        ...prev,
        qualities: [
          ...newQualities,
          {
            key_en: attr.key_en,
            key_ar: attr.key_ar,
            value_en: values.value_en,
            value_ar: values.value_ar,
            price: values?.price,
          },
        ],
      }
    })
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setCartData((prev) => ({
      ...prev,
      qualities: cartData.qualitiesBefore,
    }))
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Typography>
      {/* <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        disablePortal
        disableScrollLock
        transformOrigin={{
          vertical: 'top',
          horizontal: 'top',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      > */}
      {/* <Typography sx={{ p: 2 }}> {text}</Typography> */}
      {/* </Popover> */}
    </div>
  )
}
const FavoriteIconCard = ({ data, lng, toFavorite, proInFav }) => {
  return (
    <FavoriteIcon
      sx={{
        stroke: 'Background',
        right: 10,
        fontSize: '2.3rem',
        cursor: 'pointer',
        color: proInFav ? 'tomato' : '#DEDEDE',
        strokeWidth: proInFav ? 0 : 1,
      }}
      onClick={(e) => {
        e.stopPropagation(),
          toFavorite(data?._id)
            .unwrap()
            .then((res) => {
              toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
            })
            .catch((e) =>
              toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
            )
      }}
    />
  )
}

// =========================================custom Payment Type =====================================
const MAP_TYPE = {
  cash: {
    ar: 'الدفع في العياده',
    en: 'pay on clinic',
  },
  online: {
    ar: 'الدفع اونلاين',
    en: 'online',
  },
  both: {
    ar: 'الدفع في العياده او اونلاين',
    en: 'online or On clinic',
  },
}
// =========================================custom Payment Type =====================================

function CustomPaymentType({ type, lang }) {
  return (
    <Stack direction={'row'} gap={2}>
      <Typography variant="h6">
        {lang === 'ar' ? MAP_TYPE[type]?.ar : MAP_TYPE[type]?.en}
      </Typography>

      <Typography variant="h6">
        {' :'} {lang === 'en' ? 'Payment Type' : 'الدفع'}
      </Typography>
    </Stack>
  )
}
// =========================================custom Payment Type =====================================

// ========================Attr=====================================//
//   ========================================Attr===============================

const Attrs = ({ colors, attr, setCartData, cartData }) => {
  const { attrAciveColors } = colors
  console.log(colors)
  const [, { language: lng }] = useTranslation()
  const handleUpdateQualities = ({ attr, values }) => {
    setCartData((prev) => {
      const newQualities = prev.qualities.filter(
        (v) => v.key_en !== attr.key_en && v.key_ar !== attr.key_ar
      )
      // qualitiesBefore
      const qualitiesBeforeHover = [
        ...newQualities,
        {
          key_en: attr.key_en,
          key_ar: attr.key_ar,
          value_en: values.value_en,
          value_ar: values.value_ar,
          price: values?.price,
        },
      ]

      return {
        ...prev,
        qualities: qualitiesBeforeHover,
        qualitiesBefore: qualitiesBeforeHover,
      }
    })
  }
  const isSelectedAtt = (val) =>
    val.value_en ===
    cartData?.qualities?.findLast((v) => v.key_en === attr.key_en)?.value_en

  return (
    <Box
      key={attr._id}
      sx={{
        margin: 'auto',
        display: 'flex',
        alignItems: {
          xs: 'center',
          md: 'center',
        },
        justifyContent: 'center',
        flexDirection: {
          xs: 'column',
          md: lng === 'en' ? 'row' : 'row-reverse',
        },
        flexWrap: 'wrap',
      }}
      dir="ltr"
    >
      <Typography
        sx={{
          color: colors.attrKeyColor,
          fontWeight: 'bold',
          textAlign: lng === 'en' ? 'left' : 'right',
          marginLeft: '10px',
        }}
      >
        {attr[`key_${lng === 'en' ? 'en' : 'ar'}`]}
      </Typography>
      <ButtonGroup
        // disabled={
        //   attr.key_en ===
        //   cartData.qualities.find((v) => v.key_en === attr.key_en)?.key_en
        // }

        sx={{
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        {attr?.values?.map((val) => (
          <>
            <Button
              sx={{
                color: isSelectedAtt(val)
                  ? attrAciveColors.ActiveColor
                  : colors.attrValueColor,
                bgcolor: isSelectedAtt(val)
                  ? attrAciveColors.background
                  : `${colors.attrValueBgColor} !important`,
                borderColor: `${colors.attrValueBorderColor} !important`,
                margin: '10px 10px',
                'border-radius': ' 6px',
                border: '1px solid #ddd !important',
                boxShadow: attrAciveColors.boxShadow,
                'border-radius': '6px !important',
                fontSize: '5px !important',
                padding: '0px 10px',
              }}
              key={val.value_en}
              onClick={() =>
                handleUpdateQualities({
                  attr,
                  values: {
                    value_en: val.value_en,
                    value_ar: val.value_ar,
                    price: val?.price,
                  },
                })
              }
            >
              <MouseOverPopover
                text={`${val.price} 
            ${lng === 'en' ? 'SAR' : 'رس'}`}
                setCartData={setCartData}
                cartData={cartData}
                attr={attr}
                values={{
                  value_en: val?.value_en,
                  value_ar: val?.value_ar,
                  price: val?.price,
                }}
                sx={{
                  fontSize: '11px !important',
                }}
              >
                {val[`value_${lng === 'en' ? 'en' : 'ar'}`]}
              </MouseOverPopover>
            </Button>
          </>
        ))}
      </ButtonGroup>
    </Box>
  )
}
//   ========================================Attr===============================
// ========================Attr=====================================//
export const ShishiSingleProduct = ({ currencyValue }) => {
  const { currencyPrice, label } = useSelector((state) => state.currency)
  const labelText = label.match(/\(([^)]+)\)/)
  const currencySymbol = labelText ? labelText[1] : ''
  const colors = ShishiColors
  const { id } = useParams()
  console.log(id, 'adssadqe')
  const [categoryId, Setcategoryid] = useState()
  const {
    data: product,
    isLoading,
    isError,
    error: errorProduct,
  } = useGetSingleProductQuery(id)
  const [DeleteCart, { isLoading: DeleteFromCartLoading }] =
    useDeleteFromCartMutation()
  const [, { language: lng }] = useTranslation()
  const [addToCart, { isLoading: cardLoad }] = useAddToCartMutation()
  const [rate] = useAddRatingMutation()
  const [qImage, setQimages] = useState([])
  const [cartIn, SetCartIn] = useState(false)
  const {
    data: cartItems,
    isLoading: setCartLoading,
    isSuccess,
    isError: IsCartInErorr,
  } = useGetAllCartsQuery(undefined)
  const [paymentMethod, setPaymentMethod] = useState('')
  const [toFavorite] = useAddToSavedProductMutation()
  const { data: favPros } = useGetAllSavedProductsQuery()
  const proInFav = favPros?.data.favourite.find(
    (f) => f?._id === product?.data._id
  )
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const [cartData, setCartData] = useState({
    quantity: 1,
    qualities: [],
    id: id,
    qualityAfterprice: 0,
    qualitiesBefore: [],
  })
  useEffect(() => {
    !isError && !isLoading && Setcategoryid(product?.data?.category?.id)

    setCartData({
      quantity: 1,
      qualities: [],
      id: id,
      qualityAfterprice: 0,
      qualitiesBefore: [],
    })
  }, [product?.data?.category?.id, product])

  const updateQty = (method) => {
    method === '+'
      ? setCartData({ ...cartData, quantity: cartData?.quantity + 1 })
      : cartData.quantity > 1 &&
        setCartData({ ...cartData, quantity: cartData?.quantity - 1 })
  }

  const { currentUser } = useSelector((state) => state)

  const handleRating = (productId, rating) => {
    if (!currentUser) {
      toast.error(lng === 'en' ? 'Login first' : 'سجل دخول أولاً')
    } else {
      rate({ productId, rating })
        .unwrap()
        .then((res) =>
          toast.success(lng === 'en' ? res.success_en : res.success_ar)
        )
        .catch((e) =>
          toast.error(lng === 'en' ? e.data.error_en : e.data.error_ar)
        )
    }
  }

  useEffect(() => {
    if (isSuccess && !isError) {
      console.log(product, id, 'sadsadsdsd')
    }
    const images = cartData?.qualities?.map((q) => {
      const images = product?.data?.qualitiesImages?.map((qi) => {
        const isSelect = qi?.qualities.some((qiq) => {
          return qiq?.value_en === q.value_en
        })

        if (isSelect) {
          return qi.image
        }
        return null
      })

      return images.filter(Boolean)
    })
    const images2 = images?.filter((i) => i?.length > 0).map((i) => i[0])

    setQimages(images2)
    const exteraPrices = cartData?.qualities?.reduce(
      (acc, q) => acc + q.price,
      0
    )

    setCartData({
      ...cartData,
      qualityAfterprice: exteraPrices,
    })
  }, [cartData?.qualities])

  useEffect(() => {
    if (isSuccess) {
      const cards = [
        ...cartItems?.data?.cashItems?.items,
        ...cartItems?.data?.onlineItems?.items,
      ]
      const InCart = cards.some((item) => item.product._id === id)

      SetCartIn(InCart)
    }
  }, [id, cartItems, location.pathname, cartData])
  const HandleAddToCart = (qu) => {
    if (product?.data?.paymentType === 'both')
      if (paymentMethod === '') {
        toast?.error(
          lng === 'en'
            ? 'please Select a payment method First '
            : 'من فضلك اختر طريقه الدفع اولا'
        )
        return
      }

    if (qu.length) {
      addToCart({
        quantity: cartData.quantity,
        id: cartData.id,
        qualities: qu,
        paymentType:
          product?.data?.paymentType === 'both'
            ? paymentMethod
            : product?.data?.paymentType,
      })
        .unwrap()
        .then((res) =>
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        )
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    } else {
      addToCart({
        quantity: cartData.quantity,
        id: cartData.id,
        paymentType:
          product?.data?.paymentType === 'both'
            ? paymentMethod
            : product?.data?.paymentType,
      })
        .unwrap()
        .then((res) =>
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        )
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    }
  }
  const AddToCartFunc = (cartData) => {
    const qualities =
      cartData.qualities.length > 0 ? { qualities: cartData.qualities } : {}
    if (cartIn && !IsCartInErorr) {
      DeleteCart(cartData.id)
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    } else {
      console.log(cartData, 'cartData')
      const qualitiesAfterDeletePrice = cartData.qualities.map((item) => {
        delete item.price
        return item
      })
      HandleAddToCart(qualitiesAfterDeletePrice)
    }
    console.log(cartIn)
  }
  const handleDelete = (key) => {
    const qualitiesAfterDelete = cartData.qualities.filter(
      (quality) => quality.key_en !== key
    )
    setCartData({
      ...cartData,
      qualities: qualitiesAfterDelete,
      qualitiesBefore: qualitiesAfterDelete,
    })
  }
  const extraPrice = cartData?.qualities
    .map((q) => {
      const price = product?.data?.qualities
        .find((p) => p?.key_en === q?.key_en)
        ?.values.find((v) => v?.value_en === q?.value_en)?.price

      return price
    })
    .reduce((a, b) => a + b, 0)
  const productInCart =
    !isError &&
    cartItems?.data[
      product?.paymentType === 'cash' ? 'cashItems' : 'onlineItems'
    ]?.items?.find((item) => item?.product?._id === product?.data?._id)
  return (
    <Box
      minHeight={'100vh'}
      sx={{
        mt: {
          xs: '30px',
          md: '50px',
        },
      }}
    >
      {isLoading && !product && !isError && (
        <Container sx={{ minHeight: '60vh' }}>
          <span className="loader" />
        </Container>
      )}
      {!product || isError ? (
        <Stack
          sx={{
            height: '70vh',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              color: '#000',
              fontSize: '25px',
            }}
          >
            {/* {errorProduct?.data[`error_${lng}`]} */}
          </Typography>
        </Stack>
      ) : (
        <>
          {/* <Container
        sx={{ p: 5, minHeight: '40vh' }}
        style={{ direction: lng === 'en' ? 'ltr' : 'rtl' }}
      > */}
          {!isLoading && product ? (
            <Grid
              container
              width="90%"
              sx={{
                // justifyContent: 'space-between',
                height: { xs: 'auto', md: 'auto' },
                gap: '2%',
                my: 5,
                margin: '10px auto',
                flexDirection: lng === 'en' ? 'row-reverse' : 'row',
              }}
            >
              <Grid
                item
                md={5.7}
                xs={11.5}
                margin={{
                  xs: 'auto',
                  lg: 'unset',
                }}
                sx={{
                  width: { xs: '100%', md: '47%' },
                  gap: 2,
                  direction: lng === 'en' ? 'ltr' : 'rtl',
                  order: {
                    xs: '2',
                    md: '-1',
                  },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 'bold',
                    color: colors.titleColor,
                    wordBreak: 'break-word',
                  }}
                >
                  {product.data[`title_${lng === 'en' ? 'en' : 'ar'}`]}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    gap: 2,
                    width: 'fit-content',
                    alignItems: 'flex-start',
                  }}
                >
                  <Rating
                    disabled
                    value={product?.data?.rating}
                    sx={{ direction: lng === 'en' ? 'ltr' : 'rtl' }}
                    onChange={(_, newValue) => {
                      console.log(newValue, 'newValue')
                      handleRating(product.data._id, newValue)
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    color: colors.descColor,
                    wordBreak: 'break-word',
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      product?.data[
                        `description_${lng === 'en' ? 'en' : 'ar'}`
                      ],
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: 'fit-content',
                    alignItems: 'flex-start',
                  }}
                >
                  {product?.data?.qualities?.map((quality) => (
                    <Attrs
                      key={quality?._id}
                      colors={{
                        attrKeyColor: colors?.attrKeyColor,
                        attrValueColor: colors?.attrValueColor,
                        attrValueBgColor: colors?.attrValueBgColor,
                        attrValueBorderColor: colors?.attrValueBorderColor,
                        attrAciveColors: colors?.attrAciveColors,
                      }}
                      attr={quality}
                      setCartData={setCartData}
                      cartData={cartData}
                    />
                  ))}

                  <Stack
                    direction="row"
                    justifyContent={'flex-start'}
                    width={'100%'}
                    spacing={2}
                    flexWrap={'wrap'}
                    sx={{
                      transition: '0.5s all ease-in-out',
                    }}
                  >
                    {cartData?.qualities?.map((chip) => {
                      return (
                        <Chip
                          label={`${
                            lng === 'en' ? chip.value_en : chip.value_ar
                          }`}
                          key={chip?.key_en}
                          variant="outlined"
                          sx={{
                            margin: '10px !important',
                            padding: 1,
                            visibility: 'visible',
                            borderRadius: '5px',
                            boxShadow: colors?.attrAciveColors?.boxShadow,
                            border: '1px solid #ddd',
                          }}
                          onDelete={() => handleDelete(chip?.key_en)}
                        />
                      )
                    })}
                  </Stack>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: 'fit-content',
                    alignItems: 'flex-start',
                    direction: lng === 'en' ? 'rtl' : 'ltr',
                    fontWeight: 'bold !important',
                    my: 2,
                  }}
                >
                  <CustomPaymentType
                    lang={lng}
                    type={product?.data?.paymentType}
                  />
                </Box>
                <Stack
                  sx={{
                    width: {
                      xs: '100%',
                      md: '100%',
                      lg: '80%',
                    },
                  }}
                >
                  <Box
                    sx={{
                      border: '1px solid #A3A3A3',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {/* <Stack
                    p={2}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Typography>
                      {lng === 'en' ? 'Quantity' : 'الكمية'}
                    </Typography>
                    <Stack
                      direction={'row'}
                      sx={{
                        border: '1px solid black',
                        borderRadius: 1,
                        alignItems: 'center',
                        my: 1,
                        width: 'fit-content',
                        py: 0.5,
                        width: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        borderRadius: '0px',
                        border: '1px solid #A3A3A3'

                      }}
                    >
                     <Button
                        size="small"
                        onClick={() => updateQty('+')}
                        sx={{
                          color: 'black !important',
                          minWidth: 45,
                          fontWeight:'bold',
                          fontSize:'16px'
                        }}
                      >
                        +
                      </Button>
                      <Typography
                        sx={{
                          px: 5,
                          borderLeft: '1px solid grey',
                          borderRight: '1px solid grey',
                          fontSize:'16px'

                        }}
                      >
                        {cartData.quantity}
                      </Typography>
                      <Button
                        size="small"
                        onClick={() => updateQty('-')}
                        disabled={cartData.quantity === 1}
                        sx={{
                          color: 'black',
                          minWidth: 40,
                          fontSize:'20px'

                        }}
                      >
                       -
                      </Button>
                      
                    </Stack>
                  </Stack> */}
                    {/* <Divider sx={{ border: '1px solid lightgrey ', m: 2 }} /> */}

                    {product?.data?.paymentType === 'both' ? (
                      <Stack
                        sx={{
                          width: '100%',
                          padding: '20px',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                          }}
                        >
                        
                          {lng === 'en' ? 'payment Method' : 'طريقه الدفع'}
                        </Typography>

                        <Stack
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: {
                              xs: lng === 'space-between',
                            },

                            flexDirection: 'row',
                            width: {
                              xs: '100%',
                              sm: '100%',
                              md: '100%',
                            },
                            my: 2,
                          }}
                        >
                          <Button
                            sx={{
                              padding: '10px',
                              border: '1px solid #25CAD2',
                              color: '#25CAD2',
                              borderRadius: '5px',
                              fontWeight: 'bold',
                              color:
                                paymentMethod === 'online' ? '#fff' : '#25CAD2',
                              bgcolor:
                                paymentMethod === 'online' ? '#25CAD2' : '',
                              '&:hover': {
                                color: '#fff',
                                bgcolor: '#25CAD2',
                              },
                              width: {
                                xs: '49%',
                                sm: '49%',
                                md: '49%',
                              },
                              fontSize: {
                                xs: '13px',
                                sm: '14px',
                                md: '15px',
                              },
                              marginLeft: lng == 'ar' ? '20px' : '0px',
                              marginRight: lng == 'en' ? '20px' : '0px',

                              textTransform: 'lowercase',
                            }}
                            onClick={() => setPaymentMethod('online')}
                          >
                            {lng === 'en' ? 'online' : '    اونلاين'}
                          </Button>
                          <Button
                            sx={{
                              padding: '10px',
                              border: '1px solid #25CAD2',
                              borderRadius: '5px',
                              marginLeft: lng == 'ar' ? '20px' : '0px',
                              marginRight: '10px',
                              fontWeight: 'bold',
                              color:
                                paymentMethod === 'cash' ? '#fff' : '#25CAD2',
                              bgcolor:
                                paymentMethod === 'cash' ? '#25CAD2' : '#fff',
                              '&:hover': {
                                color: '#fff',
                                bgcolor: '#25CAD2',
                              },
                              width: {
                                xs: '49%',
                                sm: '49%',
                                md: '200px',
                              },
                              fontSize: {
                                xs: '13px',
                                sm: '14px',
                                md: '15px',
                              },
                              textTransform: 'lowercase',
                              width: '49%',
                            }}
                            onClick={() => setPaymentMethod('cash')}
                          >
                            {lng === 'en'
                              ? 'Pay On Clinick'
                              : ' الدفع في العياده'}
                          </Button>
                        </Stack>
                        <Typography
                          sx={{
                            color: '#000',
                            margin: '10px 0px',
                          }}
                        >
                          {product?.data?.paymentType === 'both' &&
                          !cartIn &&
                          paymentMethod == ''
                            ? paymentMethod === ''
                              ? lng === 'en'
                                ? 'choose Payment Type'
                                : 'اختر نوع الدفع'
                              : ''
                            : ''}
                        </Typography>
                      </Stack>
                    ) : null}

                    {/* <Divider sx={{ border: '1px solid lightgrey ', m: 2 }} /> */}

                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      p={3}
                    >
                      <Typography>
                        {lng === 'en' ? 'Price' : 'السعر'}
                      </Typography>

                      <Typography>
                        {product.data.priceAfterDiscount > 0&&product.data.priceAfterDiscount!==product.data.priceBeforeDiscount ? (
                          <Box
                            component={'span'}
                            sx={{
                              color: colors.priceBeforeColor,
                              textDecoration: `line-through 1px solid `,
                              fontSize:
                                product.data.priceAfterDiscount > 0
                                  ? '0.7rem'
                                  : 'initial',
                            }}
                          >
                            {`${(
                              product.data.priceBeforeDiscount * currencyPrice
                            ).toFixed(2)}
                           ${currencySymbol}`}
                          </Box>
                        ) : null}
                        <Box
                          sx={{
                            color: colors.priceAfterColor,
                            fontWeight: 'bold',
                            fontFamily: '#A3A3A3',
                          }}
                        >
                          {(
                            (product?.data?.finalPrice + extraPrice) *
                            currencyPrice
                          ).toFixed(2)}
                          <Box
                            component={'span'}
                            sx={{ fontSize: '0.7rem', mx: 0.5 }}
                          >
                            {currencySymbol}
                          </Box>
                        </Box>
                      </Typography>
                    </Stack>
                    <div
                            style={{
                              display: 'flex',
                              gap: 3,
                              justifyContent: 'space-around',
                            }}
                          >
                            {[
                              { components: visa, text: 'visa' },
                              { components: tamara, text: 'tamara' },
                              { components: Taby, text: 'Taby' },
                            ].map((item) => {
 
                              return (
                                <Stack
                                  key={item?.text}
                                  component={'img'}
                                  sx={{
                                    width: () => {
                                    
                                      if (item?.text  === 'Taby' || item?.text === 'tamara') {
                                        return '70px'
                                      }else{
                                        return '60%'
                                      }
                                    },
                                    my:'10px'
                                  }}
                                  src={item?.components}
                                />
                              )
                            })}
                          </div>
                          
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                      }}
                    >
                      {product?.data?.quantity > 0 ? (
                        <Button
                          variant="contained"
                          sx={{
                            // px: 2,
                            py: 1,
                            color: `${
                              productInCart ? '#fff' : colors.buttonColor
                            } !important`,
                            bgcolor: `#25CAD2 !important`,
                            fontSize: { xs: '11px', md: '12 px', xl: '14px' },
                            width: '90% !important',
                            padding: '15px',
                            margin: '10px',
                            borderRadius: '0px',
                            height: '44.92px',
                            width: '88%',
                          }}
                          disabled={
                            product?.data?.paymentType === 'both' && !cartIn
                              ? paymentMethod === ''
                                ? true
                                : false
                              : false || cardLoad || DeleteFromCartLoading
                          }
                          onClick={() => AddToCartFunc(cartData)}
                        >
                          {cardLoad || DeleteFromCartLoading ? (
                            <>
                              <Typography>
                                {lng === 'en' ? 'loading' : 'جاري الحجز'}
                              </Typography>
                              <CircularProgress
                                size={20}
                                style={{ marginRight: '4px' }}
                                color="inherit"
                              />
                            </>
                          ) : (
                            <>
                              {cartIn && !IsCartInErorr ? (
                                <>
                                  {lng === 'en'
                                    ? 'cancel Reservation'
                                    : 'الغاء الحجز'}
                                </>
                              ) : (
                                <>
                                  {lng === 'en' ? 'Reserve Now' : 'احجز الان'}
                                </>
                              )}
                            </>
                          )}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{
                            width: 'fit-content',
                            px: 2,
                            py: 1,
                            color: colors.buttonColor,
                            bgcolor: `#25CAD2 !important`,
                            height: '40px !important',
                            margin: '10px',
                            borderRadius: '0px',
                            height: '44.92px',
                            width: '88%',
                          }}
                          disabled
                        >
                          <>
                            {lng === 'en'
                              ? 'there is no reservation for now'
                              : 'لا يوجد حجز الان'}
                          </>
                        </Button>
                      )}
                      <FavoriteIconCard
                        data={product?.data}
                        proInFav={proInFav}
                        toFavorite={toFavorite}
                        lng={lng}
                      />
                    </Box>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} margin="20px auto" md={6}>
                <Stack
                  slidesPerView={1}
                  breakpoints={{
                    500: {
                      slidesPerView: 1,
                      spaceBetween: '10',
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: '10',
                    },
                    1000: {
                      slidesPerView: 1,
                      spaceBetween: '10',
                    },
                  }}
                  component={Swiper}
                  sx={{
                    border: ' 1px solid #ddd',
                    position: 'sticky',
                    top: '0px',
                    width: { xs: '100%' },
                    height: {
                      xs: '100%',
                      md: '100vh',
                    },
                    borderRadius: {
                      xs: '10px',
                      md: '0px',
                    },
                    overflow: 'hidden',
                    objectFit: 'cover',
                    objectPosition: 'center center',
                    '& .swiper-pagination-bullet-active': {
                      background: '#25CAD2',
                    },

                    '& .swiper-pagination': {
                      bottom: '-1px !important',
                    },
                    pb: 3,
                  }}
                  navigation={{
                    prevEl: navigationPrevRef?.current,
                    nextEl: navigationNextRef?.current,
                  }}
                  onBeforeInit={(swiper) => {
                    if (
                      swiper.params.navigation &&
                      typeof swiper.params.navigation !== 'boolean'
                    ) {
                      swiper.params.navigation.prevEl =
                        navigationPrevRef?.current
                      swiper.params.navigation.nextEl =
                        navigationNextRef?.current
                    }
                  }}
                  pagination={{ clickable: true }}
                  modules={[Pagination]}
                >
                  {qImage?.length > 0
                    ? qImage?.map((img) => (
                        <SwiperSlide key={img}>
                          <Zoom ZoomContent={CustomZoomContent}>
                            <Box
                              component={'img'}
                              src={`${imageBaseUrl + img}`}
                              sx={{
                                width: '100%',
                                height: { xs: '100%', md: '100vh' },
                                borderRadius: {
                                  xs: '10px',
                                  md: '0px',
                                },
                              }}
                            />
                          </Zoom>
                        </SwiperSlide>
                      ))
                    : product?.data?.images.map((img) => (
                        <SwiperSlide key={img}>
                          <Zoom ZoomContent={CustomZoomContent}>
                            <Box
                              component={'img'}
                              src={`${imageBaseUrl + img}`}
                              sx={{
                                width: '100%',
                                height: { xs: '100%', md: '100vh' },
                                borderRadius: {
                                  xs: '10px',
                                  md: '0px',
                                },
                              }}
                            />
                          </Zoom>
                        </SwiperSlide>
                      ))}
                  {/* <Stack sx={{
                    position:'absolute',
                    zIndex:'10',
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems:'center',
                    flexDirection:lng==="en"?"row":'row',
                    width:'100%',
                    left:'10px',
                    padding:'0px 10px',
                    bottom:'50%'
                   }}>
                  <Button
            ref={navigationNextRef}
            onClick={() => {
              navigationNextRef
            }}
            sx={{
              background: 'none !important',
              left: {xs:'-2%',md:'-3%'},
            }}
          >
            <ArrowBackIosIcon
              sx={{
                fontSize: { xs: '22px', sm: '30px', md: '30px', lg: '40px' },
                color: '#000',
                fontWeight: '300',
              }}
            />
          </Button>
          <Button
            ref={navigationPrevRef}
            onClick={() => {
              navigationPrevRef
            }}
            sx={{
              background: 'none !important',
              outline: 'none !important',
              right: {xs:'-2%',lg:'-3%',xl:"-2.4%"}
            }}
          >
            <ArrowForwardIosIcon
              sx={{
                fontSize: { xs: '22px', sm: '30px', md: '30px', lg: '40px' },
                color: '#000',
                fontWeight: '300',
              }}
            />
          </Button>
                  </Stack> */}
                </Stack>
              </Grid>
            </Grid>
          ) : null}
          {/* </Container> */}
          {/* <ProductComments colors={colors.commentsColors} productId={id} /> */}
          <ProductReviews
            colors={colors.commentsColors}
            product={product?.data}
            productId={product?.data?._id}
          />
        </>
      )}

      {!isError && !isLoading && (
        <Box
          sx={{
            my: 2,
          }}
        >
          <Similarproduct productId={product?.data?.id} id={categoryId} />
        </Box>
      )}
    </Box>
  )
}
