import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material'

import Rating from './Rating'
import { useTranslation } from 'react-i18next'
import DraftEditor from './draftEditor/DraftEditor'
import { useEffect, useState } from 'react'
import { useCreateCommentForProductMutation } from '../../redux/apis/commentApi'
import { toast } from 'react-toastify'

const RatingModal = ({ open, setOpen, productAssets }) => {
  console.log('productAssets', productAssets)
  const [_, { language: lang }] = useTranslation()
  const [selectedRate, setRating] = useState(0)
  const [typedComment, setTypingComment] = useState('')
  const [createCommentForProduct] = useCreateCommentForProductMutation()
  const [hasValue, setHasValue] = useState('')
  console.log(hasValue, 'hasValuehasValue')
  const handleClose = () => {
    setOpen(false)
    setRating(0)
    setTypingComment('')
  }
  const addingReviewForProduct = () => {
    let temp = {
      comment: typedComment,
      rating: selectedRate,
    }
    // selectedRate === 0 ? delete temp.rating : undefined
    createCommentForProduct({
      payload: temp,
      productId: productAssets.id,
    })
      .unwrap()
      .then((res) => {
        toast.success(
          lang === 'en'
            ? 'Thanks for your comment and will be reviewed'
            : 'شكرا على تعليقك و سوف يتم مراجعته'
        )
        handleClose()
      })
      .catch((err) => {
        toast.error(err?.data?.[`error_${lang}`])
      })
  }
  return (
    <div>
      <Modal onClose={handleClose} open={open}>
        <Box sx={{ ...style, direction: lang === 'en' ? 'ltr' : 'rtl' }}>
          <Box>
            <Typography variant="h5">
              {lang === 'en' ? `Add Rating` : `أضف تقييم`}
            </Typography>
            <Rating /* totalRating={productRating} */
              setRating={setRating}
              totalRating={selectedRate}
              id={productAssets.id}
              disable={false}
            />
          </Box>
          <Box>
            {/* <TextField fullWidth placeholder="Add Your Comment" /> */}
            <DraftEditor
              handleChange={(value) => {
                setTypingComment(value)
              }}
              setHasValue={setHasValue}
              handleBlur={(v) => {}}
            />
          </Box>
          <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
            {console.log(hasValue, 'hasValue')}
            <Button
              onClick={addingReviewForProduct}
              disabled={hasValue?.trim() === ''}
              sx={{
                width: 'fit-content',
                bgcolor: '#25CAD2 !important',
                color: '#fff',
              }}
              variant="contained"
              color="primary"
            >
              {lang === 'en' ? 'Add' : 'أضافة'}
            </Button>
            <Button
              onClick={handleClose}
              sx={{
                width: 'fit-content',
                bgcolor: 'transparent !important',
                border: `1px solid #ddd`,
                color: '#000',
              }}
              variant="contained"
              color="primary"
            >
              {lang === 'en' ? 'Cancel' : 'إلغاء'}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  )
}

export default RatingModal

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 0.75,
  maxHeight: 0.75,
  bgcolor: '#fff',
  border: '1px solid #ddd',
  boxShadow: 24,
  p: 4,
  zIndex: '1000',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}
