import { Box, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import PinterestIcon from '@mui/icons-material/Pinterest'
import i18next from 'i18next'
import { useGetFooterPrivaciesQuery } from '../../../../redux/apis/sectionsApi'
import { useGetAllCategoriesQuery } from '../../../../redux/apis/categoriesApi'

// =====================================Map=======================================
function Map({ url, ...props }) {
  return (
    <div>
      <iframe
        title="map"
        src={url}
        width="100%"
        allowFullScreen
        height="225"
        style={{ border: '0px' }}
        loading="lazy"
        {...props}
      ></iframe>
    </div>
  )


  
}
// =====================================Map=======================================
//============================footerOptions=================================
const FooterOptions = ({ lang }) => ({
  footerText:
    lang === 'en'
      ? '© 2023 All Rights Reserved.'
      : '© 2023 جميع الحقوق محفوظة.',
  information: {
    address:
      'Jarir Marketing Company, King Khaled Street, Al Murabba District, Riyadh, KSA',
    email: 'jarir@gmail.com',
    phone: '+966500458116',
  },
  social: {
    color: '#fff',
    bgColor: '#5B0888',
    facebook: 'https://www.facebook.com',
    instagram: 'https://www.instagram.com',
    linkedin: 'https://www.linkedin.com',
  },
  map: {
    url:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14841.860528388394!2d39.1436342!3d21.5677598!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3dabd2632708b%3A0x585cda4866218f65!2z2LnZitin2K_Yp9iqINio2KfZitmI2YTYp9mK2YEgfCBCaW9MaWZlIENsaW5pY3M!5e0!3m2!1sar!2seg!4v1706686777564!5m2!1sar!2seg'
  },
  bgColor: { primary: '#363636 ', secondary: '#bf9fea' },
  color: { primary: '#fff', secondary: '#5B0888' },
  variant: 'map',
})


// Exclude Pages
const excludePages = ['/sign-in', '/register', '/thankYou', '/forgetPassword']
export const pagesWithout = (pathname) =>
  excludePages.find((item) => item === pathname)
  

//============================footerOptions=================================

//=============================privcey======================================

function FooterPrivacy({ style }) {
  const navigate = useNavigate()
  const { language } = i18next
  const { data, isLoading } = useGetFooterPrivaciesQuery(undefined)

  return (
    <>
      {!isLoading && data?.data.length > 0
        ? data?.data?.map((item) => {
            return (
              <>
                <Typography
                  sx={[
                    style,
                    {
                      cursor: 'pointer',
                      fontSize: '16px !important',
                      fontWeight: { xs: '400', sm: 'bold' },
                    },
                  ]}
                  key={item}
                  onClick={() => navigate(`/policies/${item.type}`)}
                >
                  {item[`title_${language}`]}
                </Typography>
              </>
            )
          })
        : null}
    </>
  )
}

//=============================privcey======================================

//// =========================== staticLinks=============================
const links = [
  {
    ar: 'عننا',
    en: 'About Us',
    path: '/aboutUs',
  },
  {
    ar: 'تواصل معنا',
    en: 'Contact Us',
    path: '/contactUs',
  },
  {
    ar: 'مدوناتنا',
    en: 'Our blogs',
    path: '/blogs',
  },
]
// =========================== staticLinks============================================================Icons===========================================

//===============================Icons===========================================

function Logo({ url }) {
  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          lg: '300px',
        },

        display: 'grid',
        placeItems: 'center',
        py: 3,
      }}
    >
      <Box
        component={'img'}
        src={url}
        alt="logo"
        sx={{ width: 150, height: 150 }}
      />
    </Box>
  )
}

function CategorySection({ categories, color }) {
  const {
    i18n: { language },
  } = useTranslation()
  return (
    <Stack
      direction={{
        xs: 'row',
        md: 'column',
      }}
      gap={2}
      sx={{
        justifyContent: { xs: 'center', md: 'start' },
        flexWrap: 'wrap',
      }}
    >
      {categories.map((category) => (
        <Box
          key={category._id}
          sx={{
            color: color.primary,
            textDecoration: 'none !important',
          }}
          component={Link}
          to={`/clinics/${category._id}/${category?.name_en}`}
        >
          {language === 'en' ? category.name_en : category.name_ar}
        </Box>
      ))}
    </Stack>
  )
}

function ContentSection({ color, map, variant }) {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Stack
      direction={'column'}
      sx={{
        justifyContent: {
          xs: 'center',
          md: 'start',
        },
      }}
      gap={2}
    >
      <Stack
        direction={{ xs: 'row', md: 'column' }}
        gap={3}
        justifyContent={'center'}
      >
        {links.map((link) => (
          <Box
            key={link.path}
            sx={{
              color: color.primary,
              textDecoration: 'none !important',
            }}
            component={Link}
            to={link.path}
          >
            {language === 'en' ? link.en : link.ar}
          </Box>
        ))}
      </Stack>
      {(variant === 'map' || variant === 'form-map') && map && (
        <Box sx={{ pt: 5 }}>
          <Map url={map.url} />
        </Box>
      )}
    </Stack>
  )
}

function IconLink({ social, icon, link }) {
  return (
    <Box
      component={Link}
      target="_blank"
      to={link}
      sx={{
        textDecoration: 'none !important',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Box
        component={icon}
        width="fit-content"
        sx={{ color: social?.color, height: { xs: '15', md: '66px' }, width: 66 }}
      />
    </Box>
  )
}

function SocialSection({ social }) {
  if (!social) return null

  return (
    <Stack direction="column" gap={2}>
      {social?.phone && (
        <Box
          component={Link}
          target="_blank"
          sx={{
            textDecoration: 'none !important',
            width: 'fit-content',
          }}
          to={`tel:${social.phone}`}
        >
          <Typography sx={{ color: social?.color, fontSize: 20 }}>
            {social.phone}
          </Typography>
        </Box>
      )}
      <Stack
        direction={{ xs: 'row', md: 'column' }}
        gap={2}
        justifyContent={{
          xs: 'center',
          md: 'start',
        }}
      >
        {Object.keys(social).map((key) => {
          const Icon = {
            facebook: FacebookIcon,
            instagram: InstagramIcon,
            linkedin: LinkedInIcon,
            twitter: TwitterIcon,
            pinterest: PinterestIcon,
          }[key]
          if (!Icon) return null
          return (
            <IconLink
              social={social}
              link={social[key] || ''}
              icon={Icon}
              key={key}
            />
          )
        })}
      </Stack>
    </Stack>
  )
}

function StayInformed({ color, bgColor, variant }) {
  const {
    i18n: { language },
  } = useTranslation()
  const navigate = useNavigate()
  return (
    <Stack
      direction={'column'}
      justifyItems={'center'}
      gap={2}
      alignItems={{
        xs: 'center',
        md: 'start',
      }}
    >
      <Typography
        sx={{
          color: color.primary,
          textTransform: 'capitalize',
          fontWeight: 'bold',
          fontSize: {
            xs: 20,
            md: 40,
          },
        }}
      >
        {language === 'en' ? 'Stay informed' : 'ابقى على اطلاع'}
      </Typography>
      {(variant === 'form-map' || variant === 'form') && (
        <Stack
          sx={{
            width: '100%',
            gap: 2,
          }}
        >
          <Box
            component={'input'}
            type="text"
            placeholder={language === 'en' ? 'Email' : 'البريد الالكتروني'}
            sx={{
              color: color.primary,
              '::placeholder': { color: color.secondary },
              fontSize: {
                xs: 10,
                md: 20,
              },
              backgroundColor: 'transparent',
              padding: '5px 10px',
              border: 'none',
              outline: 'none !important',
              borderBottom: `1px solid ${color.primary}`,
            }}
          />
          <Box
            component={'textarea'}
            placeholder={language === 'en' ? 'Write Here' : 'اكتب هنا'}
            rows={5}
            sx={{
              color: color.primary,
              '::placeholder': { color: color.secondary },
              fontSize: {
                xs: 10,
                md: 20,
              },
              backgroundColor: 'transparent',
              padding: '5px 10px',
              outline: 'none !important',
              border: `1px solid ${color.primary}`,
            }}
          />
        </Stack>
      )}

      <Button
        variant="contained"
        onClick={() => navigate('/register')}
        sx={{
          color: color.primary,
          bgcolor: bgColor.secondary,
          padding: '10px 20px',
          '&:hover': {
            bgcolor: bgColor.secondary,
          },
        }}
      >
        {language === 'en' ? 'Sign Up' : 'اشترك'}
      </Button>
    </Stack>
  )
}

function TomoorMap({ logo }) {
  const {
    i18n: { language },
  } = useTranslation()

  const { bgColor, color, variant, social, map } = FooterOptions({ language })
  const { data, isLoading, isSuccess } = useGetAllCategoriesQuery(null)
  const { pathname } = useLocation()
  const categories = data?.data || []

  const dir = language === 'ar' ? 'rtl' : 'ltr'
  return (
    <Stack
      direction={{
        xs: 'column',
        lg: 'row',
      }}
      sx={{
        minHeight: 300,
        fontWeight: 'bold',
        display:
          pathname === '/thankYou' ||
          pathname === '/sign-in' ||
          pathname === '/register' ||
          pathname === '/forgetPassword' ||
          pathname === '/notfound'
            ? 'none'
            : 'flex',
      }}
      dir={dir}
    >
      <Logo url={logo} />
      <Box bgcolor={`#1DCAD3`} width={'100%'}>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          gap={{
            xs: 2,
            lg: 10,
          }}
          pt={{
            xs: 2,
            md: 10,
          }}
          px={2}
          sx={{
            flexGrow: 1,
            justifyContent: 'space-evenly',
            height: '100%',
            pb: 10,
            maxWidth: 1200,
          }}
        >
          <ContentSection color={color} map={map} variant={variant} />
          <Stack direction={'column'} alignItems={'center'}>
            <FooterPrivacy
              style={{
                color: color.primary,
                textDecoration: 'none !important',
                fontSize: 18,
                fontWeight: 'bold',
              }}
            />
          </Stack>
          {isLoading && <div>loading...</div>}
          {isSuccess && (
            <CategorySection color={color} categories={categories} />
          )}
          <SocialSection social={social} />
        </Stack>
      </Box>
    </Stack>
  )
}

export default TomoorMap
