import React, { useState, useRef } from 'react'
import { Box, IconButton, Stack, VideoPlayerIcon } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import videoPath from './biolifeVideo.mp4'

const VideoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef(null)

  const togglePlay = () => {
    const video = videoRef.current
    if (video.paused) {
      video.play()
      setIsPlaying(true)
    } else {
      video.pause()
      setIsPlaying(false)
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
         mx: 'auto',
         width:'100%'
      }}
    >
      <Stack
      component={'video'}
        ref={videoRef}
        sx={{ width: '100%', height: {
          xs:'400px',
          md:'500px',
          lg:'500px',

        }, objectFit: 'fill' ,
        borderRadius:'10px'
      
      }} //Automatically adjust the height to maintain the aspect ratio
        src={videoPath}
        autoPlay={true}
        muted
        loop
      />
      <IconButton
        onClick={togglePlay}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'rgba(0,0,0,0.3)',
        }}
      >
        {isPlaying ? (
          <PauseIcon fontSize="large" />
        ) : (
          <PlayArrowIcon fontSize="large" />
        )}
      </IconButton>
    </Box>
  )
}

export default VideoPlayer
