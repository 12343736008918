export const baseUrl = 'https://biolife.world:8080/api/v1'
export const imageBaseUrl = `https://biolife.world:8080/uploads/`
export const notificationsUrl = 'https://biolife.world:8080'
export const baseUrlClient = 'https://biolife.world/'
export const dashboardUrl = 'https://biolife.world:5000'


// export const baseUrl = 'http://localhost:3001/api/v1'
// export const imageBaseUrl = 'http://localhost:3001/uploads/'
// export const notificationsUrl = 'http://localhost:3001'
// export const baseUrlClient = 'http://localhost:3000/'
// export const dashboardUrl = 'http://localhost:5000'
