import { useEffect } from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import AppRoutes from './router/Router'
import logoPath from './assets/svg/logo.svg'
import { useTranslation } from 'react-i18next'
import { useLazyGetMeQuery } from './redux/apis/UserApis.js'
import Navbar_ButtunStyle from './layouts/Navbars/buttonStyle/without/Navbar_ButtunStyle.jsx'
import { useCreateGuestUserMutation } from './redux/apis/gestUserApi.js'
import TomoorMap from './components/Footers/Tomoor/TomoorMap'

function App() {
  const [getMe] = useLazyGetMeQuery()
  const [, { _, changeLanguage }] = useTranslation()
  const [createGuestUser] = useCreateGuestUserMutation()
 
  useEffect(() => {
    if (localStorage?.i18nextLng) {
      changeLanguage(localStorage?.i18nextLng)
    }
  }, [])
  useEffect(() => {
    if (localStorage.token) {
      getMe()
      .unwrap()
      .then((res) => res)
      .catch((err) => {
        createGuestUser()
          .unwrap()
          .then((res) => {
            localStorage.setItem('token', res.token)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    }
  }, [])

  useEffect(() => {
    if (!localStorage.token) {
      createGuestUser()
      .unwrap()
      .then((res) => {
        localStorage.setItem('token', res.token)
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [])


  

  return (
    <BrowserRouter>
      {/* <ErrorBoundary FallbackComponent={<ErrorBoundaryPage />}> */}
      <Navbar_ButtunStyle logoPath={logoPath} />
      <AppRoutes />
      <TomoorMap logo={logoPath} />
      {/* </ErrorBoundary> */}
    </BrowserRouter>
  )
}
export default App
