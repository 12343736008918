import {
  Autocomplete,
  Box,
  Container,
  TextField,
  Typography,
  Stack,
  Grid,
  FormLabel,
  Divider,
  Button,
  InputBase,
  CircularProgress,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useMemo, useState, useEffect } from 'react'
import * as yup from 'yup'
import { useAddOrderMutation } from '../../../redux/apis/ordersApi'
import { useGetUserCodeMutation } from '../../../redux/apis/verifiedCodeApi'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {
  useGetAllCartsQuery,
  useVerifyCartMutation,
} from '../../../redux/apis/cartApi'
import { cityEnglish } from '../../../components/providers/city-english'
import VerifiedCode from '../../../components/verification/VerifiedCode'
import { useLazyGetMeQuery } from '../../../redux/apis/UserApis'
import { CheckOutTomoorColors } from './Style'
import { useTranslation } from 'react-i18next'
import { imageBaseUrl } from '../../../constants/baseUrl'
import { useSelector } from 'react-redux'

// ================================|| CHECKOUT - BILLING DETAILS ||================================ //
const BillingDetails = (props) => {
  const [openModal, setOpenModal] = useState(false)
  const [userPhone, setUserPhone] = useState('')
  const [userData, setUserData] = useState({})
  const [_, { language }] = useTranslation()
  const [addOrder, { isLoading: orderLoad }] = useAddOrderMutation()
  const { refetch } = useGetAllCartsQuery(undefined)
  const [getUserCode, { isLoading: codeLoad }] = useGetUserCodeMutation()
  const navigate = useNavigate()
  const [getMe] = useLazyGetMeQuery(undefined)
  const [submitCheckout, { isLoading: verfyCouponLoading }] =
    useVerifyCartMutation()
  useEffect(() => {
    getMe(undefined).then(({ data }) => {
      let userData = data?.data
      if (userData) {
        setUserData(userData)
        formik.setFieldValue('email', userData?.email || '')
        formik.setFieldValue('name', userData?.name || '')
        formik.setFieldValue(
          'phone',
          userData?.phone
            ? userData?.phone?.startsWith('966')
              ? userData?.phone?.split('966')?.[1]
              : userData?.phone
            : ''
        )
      }
    })
  }, [])
  const handelerCode = (code, phone) => {
    getUserCode({ code, phone })
      .unwrap()
      .then((res) => {
        refetch()
        toast.success(res[`success_${language}`])
        if (res.paymentType === 'cash') {
          navigate('/thankYou')
        } else {
          navigate('/select-payment')
        }
      })
      .catch((err) => {
        toast.error(err.data[`error_${language}`])
      })
  }
  
  const formik = useFormik({
    initialValues: {
      city: '',
      name: '',
      area: '',
      address: '',
      postalCode: '000',
      phone: '',
      email: '',
      orderNotes: '',
      // country: 'SA',

    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required(language === 'en' ? 'Name Required' : 'الاسم مطلوب'),
      area: yup
        .string()
        .required(language === 'en' ? 'Area Required' : 'المنطقة مطلوبة'),
      address: yup
        .string()
        .required(language === 'en' ? 'Address Required' : 'العنوان مطلوب'),
      city: yup
        .string()
        .required(language === 'en' ? 'City Required' : 'المدينة مطلوبة'),
      phone: yup
        .string()
        .min(
          9,
          language === 'en'
            ? 'Phone must be 9 digits'
            : 'الهاتف يجب ان يكون 9 ارقام'
        )
        .max(9)
        .required(
          language === 'en'
            ? 'Phone must be 9 digits'
            : 'الهاتف يجب ان يكون 9 ارقام'
        ),
      // .matches(
      //   /^\+?[0-9]+$/, // Updated regular expression to allow only digits
      //   language === 'en'
      //     ? 'Phone must contain only numbers'
      //     : 'يجب أن يحتوي الهاتف على أرقام فقط'
      // )
      email: yup
        .string()
        .required(
          language === 'en' ? 'Email Required' : 'البريد الالكتروني مطلوب'
        )
        .email(
          language === 'en' ? 'Email is invalid' : 'البريد الالكتروني غير صالح'
        ),
      postalCode: yup
        .string()
        .matches(
          /^\d+$/,
          language === 'en'
            ? 'Postal Code must contain only numbers'
            : 'يجب أن يحتوي الرمز البريدي على أرقام فقط'
        )
        .required(
          language === 'en' ? 'Postal Code Required' : 'الرمز البريدي مطلوب'
        ),
      orderNotes: yup.string().optional(),
    }),
    onSubmit: (values) => {
  
      const data = {
        ...values,
        phone: values.phone.startsWith('+966')
          ? values.phone
          : `+966${values.phone}`,
      }
      if (values.orderNotes === '') {
        delete data.orderNotes
      }
      const couponData = JSON.parse(localStorage.getItem('couponData'))
      if (couponData && couponData?.couponEnter !== '') {
        submitCheckout({
          productsIds: couponData?.products,
          code: couponData?.couponEnter,
        })
          .unwrap()
          .then((res) => {
            toast.success(res[`success_${language === 'en' ? 'en' : 'ar'}`])
            // cartApi.endpoints.getAllCarts.initiate()
            localStorage.removeItem('couponData')
            addOrder(data)
              .unwrap()
              .then((res) => {
                setUserPhone(res?.data?.phone)
                setOpenModal(true)
              })
              .catch((err) => {
                toast.error(err.data[`error_${language}`])
              })
            // nav('/checkout')
          })
          .catch((e) => {
            // toast.error(e?.data[`error_${lng === 'en' ? 'en' : 'ar'}`]);
            toast.error(e?.data[`error_${language === 'en' ? 'en' : 'ar'}`])
            localStorage.removeItem('couponData')
          })
      } else {
        addOrder(data)
          .unwrap()
          .then((res) => {
            setUserPhone(res?.data?.phone)

            setOpenModal(true)
          })
          .catch((e) => {
            toast.error(e?.data[`error_${language === 'en' ? 'en' : 'ar'}`])
          })
      }
    },
  })

  const Data = useMemo(() => cityEnglish, [cityEnglish, language])
  const { handleBlur, handleChange } = formik
  const calculateProductsAfterDiscount = (
    arr1,
    arr2,
    products,
    percent,
    callback
  ) => {
    const after1 = arr1.map((item) => {
      const { total } = item
      const itemAfterCoupon = (percent / 100) * total
      const isDiscount = products.includes(item.product._id)
      if (isDiscount) return { ...item, total: total - itemAfterCoupon }
      return item
    })
    const after2 = arr2.map((item) => {
      const { total } = item
      const itemAfterCoupon = (percent / 100) * total
      const isDiscount = products.includes(item.product._id)
      if (isDiscount) return { ...item, total: total - itemAfterCoupon }
      return item
    })

    const total = [...after1, ...after2].reduce(
      (acc, item) => item.total + acc,
      0
    )
    return callback(total)
  }

  return (
    <Box
      sx={{
        direction: language === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Container
        sx={{
          py: 2,
        }}
      >
        <Box
          sx={{
            borderBottom: 2,
            borderColor: props.color ? props.color : 'gray',
            py: 2,

            marginBottom: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '25px', md: '30px' },
              color: props.color?.title,
            }}
          >
            {language === 'en' ? 'Billing Details' : 'تفاصيل الفاتورة'}
          </Typography>
        </Box>
        <Divider sx={{ borderColor: props.color?.label, mt: 2 }} />
        {/* form */}

        <form onSubmit={formik.handleSubmit}>
          <Box mt={4}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Name' : 'الاسم'}
            </FormLabel>
            <InputBase
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
              sx={{
                width: '100%',
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.name && formik.errors.name ? (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.name}
              </Typography>
            ) : null}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'City' : 'المدينة'}
            </FormLabel>
            <Autocomplete
              disablePortal
              clearOnBlur
              onBlur={formik.handleBlur}
              // value={formik.values.city}
              getOptionLabel={(option) =>
                language === 'en' ? option.en : option.ar
              }
              onChange={(e, value) => {
                formik.setFieldValue('city', value?.en)
              }}
              id="combo-box-demo"
              options={Data?.filter((city) => city?.en === 'Jeddah')}
              sx={{
                width: '100%',
                height: { xs: '65px', sm: '75px', md: '55px' },
                borderRadius: 0,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
                '.MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-fullWidth.MuiInputBase-root.MuiOutlinedInput-root.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root':
                  {
                    border: `2px solid ${
                      props.color?.label ? props.color?.label : 'gray'
                    } !important`,
                    borderRadius: '0 !important',
                  },
                '.css-segi59': {
                  border: `2px solid ${
                    props.color?.label ? props.color?.label : 'gray'
                  } !important`,
                  borderRadius: '0 !important',
                  outline: 'none !important',
                },
              }}
              renderInput={(params) => <TextField name="city" {...params} />}
            />

            {formik.touched.city && formik.errors.city && (
              <Typography sx={{ color: 'red', mt: 1 }}>
                {formik.errors.city}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Area' : 'المنطقة'}
            </FormLabel>
            <InputBase
              name="area"
              onBlur={formik.handleBlur}
              value={formik.values.area}
              onChange={formik.handleChange}
              sx={{
                width: '100%',
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {
              console.log(formik.errors,'dsaaaaaaaa413321MOhab')
            }
            {formik.touched.area && formik.errors.area && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.area}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Address' : 'العنوان'}
            </FormLabel>
            <InputBase
              name="address"
              onBlur={formik.handleBlur}
              value={formik.values.address}
              onChange={formik.handleChange}
              sx={{
                width: '100%',
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.address && formik.errors.address && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.address}
              </Typography>
            )}
          </Box>
          {/* <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Postal Code' : 'الرمز البريدي'}
            </FormLabel>
            <InputBase
              name="postalCode"
              onBlur={formik.handleBlur}
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              sx={{
                width: '100%',
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.postalCode && formik.errors.postalCode && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.postalCode}
              </Typography>
            )}
          </Box> */}
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Phone' : 'الهاتف'}
            </FormLabel>

            <InputBase
              name="phone"
              onBlur={formik.handleBlur}
              value={
                userData?.email && !formik.values.phone.startsWith('+966')
                  ? `+966${formik.values.phone}`
                  : formik.values.phone
              }
              onChange={(event) => {
                const { value, selectionStart, selectionEnd } = event.target
                const prefix = '+966'
                // Check if the value starts with "+966" and only allow editing the part after it
                if (userData?.email && !userData?.phone) {
                  if (value.startsWith('+966')) {
                    const userInput = value.substring(4) // Remove "+966" from the input
                    formik.handleChange('phone')(userInput) // Update the user's input
                  } else {
                    // If the input does not start with "+966," keep the previous value
                    formik.handleChange('phone')(formik.values.phone)
                  }
                } else {
                  // When user doesn't have an email, prevent deletion of "+966"
                  if (
                    (selectionStart && selectionStart < prefix.length) ||
                    (selectionEnd && selectionEnd < prefix.length)
                  ) {
                    event.preventDefault()
                  } else {
                    formik.handleChange('phone')(value)
                  }
                }
              }}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                width: '100%',
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.phone && formik.errors.phone && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.phone}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en' ? 'Email' : 'البريد الالكتروني'}
            </FormLabel>
            <InputBase
              name="email"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                width: '100%',
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.email && formik.errors.email && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.email}
              </Typography>
            )}
          </Box>
          <Box mt={3}>
            <FormLabel
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color: props.color?.label ? props.color?.label : '#4e6f72',
              }}
            >
              {language === 'en'
                ? ' Order Notes (optional)'
                : 'ملاحظات الطلب (اختياري)'}
            </FormLabel>
            <InputBase
              name="orderNotes"
              onBlur={formik.handleBlur}
              value={formik.values.orderNotes}
              onChange={formik.handleChange}
              sx={{
                height: { xs: '65px', sm: '75px', md: '60px' },
                borderRadius: 0,
                border: `2px solid ${
                  props.color?.label ? props.color?.label : 'gray'
                }`,
                mt: 2,
                '& input': {
                  fontSize: { xs: '1rem', sm: '20px', md: '22px' }, // Adjust the font size as needed
                  ml: 2,
                },
                width: '100%',
                backgroundColor: 'transparent !important',
                px: language === 'en' ? 0 : 2,
              }}
            />
            {formik.touched.orderNotes && formik.errors.orderNotes && (
              <Typography sx={{ color: 'red' }}>
                {formik.errors.orderNotes}
              </Typography>
            )}
          </Box>

          <Button
            disabled={orderLoad}
            type="submit"
            sx={{
              mt: 6,
              mb: 5,
              width: 'fit-content',
              height: '60px',
              borderRadius: '6px',
              bgcolor: props.button?.backgroundColor
                ? `${props.button?.backgroundColor} !important`
                : 'black !important',
              color: props.button?.color
                ? `${props.button?.color} !important`
                : 'white',
              fontSize: { xs: '15px', md: '20px' },
              textTransform: 'none',
              marginRight: 'auto',
              height: {
                xs: '40px',
                md: '75px',
              },
              padding: {
                xs: '10px 30px',
                lg: '10px 60px',
              },
            }}
          >
            {orderLoad ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress
                  sx={{
                    color: props.button?.color || 'white',
                  }}
                />
              </Box>
            ) : language === 'en' ? (
              'Order Now'
            ) : (
              'اطلب الآن'
            )}
          </Button>
        </form>
      </Container>

      <VerifiedCode
        open={openModal}
        handelerCode={handelerCode}
        userPhone={userPhone}
        loading={codeLoad}
      />
    </Box>
  )
}
// ================================================================================================ //

// ================================|| CHECKOUT - ORDER SUMMARY ||================================ //

const cartPrice = (total, quantity, price) => {
  const pricePlusTax = total / quantity
  const tax = pricePlusTax - price
  return tax
}

const OrderSummary = ({ colors }) => {
  const { currencyPrice, label } = useSelector((state) => state.currency)
  const labelText = label.match(/\(([^)]+)\)/)
  const currencySymbol = labelText ? labelText[1] : ''
  const [_, { language }] = useTranslation()
  const { data, isSuccess } = useGetAllCartsQuery(undefined)
  const cashItems = data?.data.cashItems?.items || []
  const onlineItems = data?.data.onlineItems?.items || []
  const productInCart = [...cashItems, ...onlineItems]
  const couponData = JSON.parse(localStorage.getItem('couponData'))
  const ProductDiscount = (product) => {
    if (JSON.parse(localStorage.getItem('couponData'))) {
      const { products, couponEnter, persentage } = JSON.parse(
        localStorage.getItem('couponData')
      )
      console.log(products, product)
      if (products?.length && couponEnter !== '') {
        return products?.some((item) => item === product?.product?._id)
      }
    }
  }
  return (
    <Box
      sx={{
        border: `1px solid ${colors?.title ? colors?.title : 'gray'}`,
        mt: { xs: '25px', md: '65px' },
        mb: { xs: '25px', md: '0px' },
        mx: { xs: '10px', md: '0px' },
      }}
    >
      <Box
        sx={{
          overflowY: 'auto', // Apply scroll only if more than 3 items
          width: '100%',
        }}
      >
        {productInCart?.map((item, index) => (
          <>
            <Stack
              direction="row"
              sx={{
                my: 2,
                width: '100%',
                p: 2,
                // borderBottom: '2px solid #e0e0e0',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  width: { xs: '100px', md: '100px' },
                  height: { xs: '100px', md: '70px' },
                  overflow: 'hidden',
                  borderRadius: '10px',
                }}
              >
                <img
                  src={`${imageBaseUrl}${item.product.images[0]}`}
                  alt={item.title}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>

              <Box>
                <Typography
                  sx={{
                    color: colors?.title ? colors?.title : 'gray',
                  }}
                >
                  {item.product[`title_${language}`].slice(0, 20)}
                </Typography>
                <Box sx={{ color: colors?.title }}>
                  {language == 'en' ? 'Quentity' : 'العدد'} {item?.quantity}
                </Box>
              </Box>

              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  sx={{
                    color: colors?.title ? colors?.title : 'gray',
                  }}
                >
                  {currencySymbol}{' '}
                  {/* {item.product.priceAfterDiscount
                    ? (((cartPrice(
                        item.total,
                        item.quantity,
                        item.product.finalPrice
                      ) + item.product.priceAfterDiscount)*currencyPrice).toFixed(2))
                    : ((((cartPrice(
                      item.total,
                      item.quantity,
                      item.product.finalPrice
                    ) + item.product.priceBeforeDiscount)-
              couponData?.couponEnter!==""?
              (     
                (cartPrice(
                  item.total,
                  item.quantity,
                  item.product.finalPrice
                ) + item.product.priceBeforeDiscount)*couponData?.persentage/100)
                :0
                    )*currencyPrice).toFixed(2))}
                  */}
                  {(couponData?.couponEnter !== '' && ProductDiscount(item)
                    ? item?.total - (item?.total * couponData.persentage) / 100
                    : item?.total) * currencyPrice}
                </Typography>
              </Stack>
            </Stack>
            {index + 1 < productInCart.length ? (
              <Divider sx={{ border: '1px solid #e0e0e0' }} />
            ) : null}
          </>
        ))}
      </Box>
    </Box>
  )
}

// ================================================================================================ //

// ===========================|| CHECKOUT ||=========================== //

const CheckOutTomoor = () => {
  const [, { language }] = useTranslation()

  return (
    <Box
      sx={{
        direction: language === 'en' ? 'ltr' : 'rtl',
        mt: 5,
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: '90%' },
          mx: 'auto',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
          }}
        >
          <Grid item xs={12} md={7} lg={8}>
            <BillingDetails
              color={CheckOutTomoorColors.color}
              button={CheckOutTomoorColors.button}
            />
          </Grid>

          <Grid item xs={12} md={5} lg={3.6}>
            <OrderSummary colors={CheckOutTomoorColors.color} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CheckOutTomoor
