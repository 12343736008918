import { appColors } from "../../../constants/colors";

export const ShishiColors = {
  attrKeyColor: '#A3A3A3',
  attrValueColor: '#A3A3A3',
  buttonBgColor: appColors.mainColor,
  imageBackgroundColor: '#DBC684',
  buttonColor: 'white !important',
  priceBeforeColor: '#585858',
  titleColor: '#333',
  savedIconColor: '#6B6B6B !important',
  attrValueBgColor: '#fff',
  descColor: '#6B6B6B !important',
  priceAfterColor: '#333',
  attrValueBorderColor: '#eee',
  bestSellerBtnBG: '#c4a035',
  attrAciveColors: {
    background: '#fff !IMPORTANT',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
    ActiveColor: '#333 !important',
  },
  commentsColors: {
    sectionBg: '#ddd',
    heading: '#333',
    borderColor: 'greem',
  },
  Breadcrumbs: {
    bgcolor: '#ddd',
    primary: '#ddd',
    secondary: '#ddd',
  },
}
 