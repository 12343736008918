import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
} from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import 'swiper/css'
import { useTranslation } from 'react-i18next'
import {
  AllProductsGridStyle,
  CategoriesStyle,
  filterMenuStyles,
} from './categoriespage.style.jsx'
import { CiSearch } from 'react-icons/ci'
import Breadcrumbs from '../../../components/BreadCrumbs/BreadCrumbs.jsx'
import {
  useGetAllProductsByCategoryQuery,
  useGetAllProductsBySubSubCategoryIdQuery,
} from '../../../redux/apis/ProductApis.js'
import { useLazyGetAllAttributesQuery } from '../../../redux/apis/attributeApi.js'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { colors } from './categoriespage.style.jsx'
import RefreshIcon from '@mui/icons-material/Refresh'
import CloseIcon from '@mui/icons-material/Close'
import Card from '../../../components/Cards/Scrolls/Scrolling1/index.jsx'
import { AllProductsGridStyles } from '../categoriespage/categoriespage.style.jsx'
import FilterAttributesMenu from '../../../components/FilterAttributesMenu/FilterAttributesMenu.jsx'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useGetAllSubSubCategoriesQuery } from '../../../redux/apis/subcategoriesApi.js'

// ===================================search Filters===============================

//===================================PreoductGrid=============================
function AllProductsGrid({ itemId }) {
  const { data, error, isLoading } = useGetAllProductsBySubSubCategoryIdQuery(
    `?subSubCategory=${itemId}`
  )
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        sm: 2,
        md: 4,
        lg: 2,
        xl: 3,
      }}
      pt={1}
      width={{ xs: '97%', sm: '100%', lg: '90%' }}
      justifyContent={'center'}
    >
      {isLoading ? null : data && !error ? (
        data?.data.map((card, index) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            xl={4}
            key={index}
            sx={AllProductsGridStyles.CardItem}
          >
            <Box
              sx={{
                height: {
                  md: 438,
                  xs: 400,
                },
              }}
            >
              <Card data={card} />
            </Box>
          </Grid>
        ))
      ) : (
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          xl={4}
          sx={AllProductsGridStyles.CardItem}
        >
          <Typography>{error.data.error_en}</Typography>
        </Grid>
      )}
    </Grid>
  )
}
//===================================PreoductGrid=============================
// --------------------- Filter Attributes--------------------

//===================================productPage=============================

export const AllSubSubCategoriesPage = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [filterQuery, setFilterQuery] = useState('')
  const [, { language: lng }] = useTranslation()
  const nav = useNavigate()
  const { data: allProducts, isLoading: allProductsLoading } =
    useGetAllSubSubCategoriesQuery(
      `${filterQuery ? `&${query}` : query}${
        query ? `&${filterQuery}` : filterQuery
      }`
    )
  const Styles = CategoriesStyle(lng)
  console.log(Styles.Breadcrumbs)
  useEffect(() => {
    setData(allProducts?.data)
    setLoading(allProductsLoading)
  }, [allProducts, allProductsLoading, query])
  console.log('dadad dataaaaaaaaaaaaaaaa', data)
  return (
    <>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          my: 5,
          px: 3,
          direction: lng === 'en' ? 'ltr' : 'rtl',
          bgcolor: colors.bgcolor,
          wordBreak: 'break-word',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: colors.primary,
            fontSize: {
              lg: '20px',
              xs: '16px',
            },
            ':hover': {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
            display: 'inline-block',
          }}
          onClick={() => nav('/')}
        >
          {lng === 'en' ? 'Home' : 'الرئسية'}
        </Typography>
        <NavigateNextIcon
          fontSize="small"
          sx={{
            transform: `rotateY(${lng === 'ar' ? '180deg' : '0'})`,
            color: colors.primary,
          }}
        />
        <Typography
          variant="h6"
          sx={{
            cursor: 'default',
            color: colors.secondary,
            fontSize: {
              lg: '15px',
              xs: '15px',
            },
          }}
        >
          {lng === 'en' ? 'sub sub categories' : 'الأقسام الفرعية الفرعية'}
        </Typography>
      </Stack>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          direction: lng === 'en' ? 'ltr' : 'rtl',
          px: '20px',
        }}
      >
        <Box
          sx={{
            display: { xs: 'block', sm: 'block', md: 'flex' },
            direction: lng === 'en' ? 'ltr' : 'rtl',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            px: {
              md: '0px',
              xs: '5px',
            },
          }}
        >
          {/* <Search onChange={(value) => setQuery(value)} /> */}

          <FilterAttributesMenu
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
          />
        </Box>
      </Box>
      <Box sx={Styles.Box}>
        <Stack
          direction={'column'}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {!loading ? (
            data?.map((item) => (
              <Box>
                <Typography
                  sx={Styles.Typography}
                  onClick={() =>
                    nav(
                      `/subSubCategories/${item._id}/${item.name_en.replace(
                        /\s/g,
                        ''
                      )}`
                    )
                  }
                >
                  {item[`name_${lng === 'en' ? 'en' : 'ar'}`]}
                </Typography>
                <AllProductsGrid itemId={item?._id} />
              </Box>
            ))
          ) : (
            <span className="loader"></span>
          )}
          {!loading && (data?.length < 1 || data?.length === undefined) && (
            <Typography color={'error'} sx={{ m: 5, fontSize: '2rem' }}>
              {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
            </Typography>
          )}
          {/* not fo */}
        </Stack>
      </Box>
    </>
  )
}
