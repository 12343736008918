import { Box, Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Card from '../../../Cards/Scrolls/Scrolling1'
import { useGetAllProductsByCategoryQuery, useGetMostNewiestProductsQuery } from '../../../../redux/apis/ProductApis'
import { ScrollColors } from './colors'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

const ErrorSection = ({ isError, error }) => {
  const [, { language: lang }] = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <Typography
        fontSize={'1.5rem'}
        my={10}
        textAlign={'center'}
        color="error"
      >
        {isError
          ? error?.data && error?.data[`error_${lang}`]
          : error?.data && error?.data[`error_${lang}`]}
      </Typography>
    </Box>
  )
}

const SliderHeader = ({ colors,category }) => {
  const [, { language: lang }] = useTranslation()
  const navigate = useNavigate()
  return (
    <Box sx={{ textAlign: {
      xs:'center',
      md:lang === 'en' ? 'start' : 'end'
    } }}>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: { xs: '2.1rem', sm: '2.1rem', lg: '4rem' },
          color: colors.title,
          textAlign:  {
            
              xs:'center',
              md:lang === 'en' ? 'start' : 'end'
        
          },
        }}
      >
        {lang === 'en' ? category?.name_en:category?.name_ar}
      </Typography>
      <Button
        variant="outlined"
        sx={{
          mt: 2,
          color: colors.buttonTextColor,
          // borderRadius: props.borderRaduisBtn ? '40px' : '0px',
          padding: { xs: '0.4rem 2rem', lg: '0.6rem 3rem' },
          border: `1px solid ${colors.buttonBorderColor} !important`,
          backgroundColor: colors.buttonBg,
          fontSize: { xs: '20px', md: '1.8rem' },
          borderRadius: '10px',

          height: {
            xs: '40px',
            md:'50px'
          },
          width:{
             md:'100%'
          }

        }}
        onClick={() => {
          navigate(`/clinics/${category?._id}/${category?.name_en}`)
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '10px', sm: '15px', md: '18px', lg: '22px' },
            // i need to make words not capital
            textTransform: 'capitalize',
            fontWeight:'500 !important'
          }}
        >
          {lang === 'en' ? 'View All' : 'عرض الكل'}
        </Typography>
      </Button>
    </Box>
  )
}

const Slider = ({ data, lang }) => {
  return (
    <Grid
      width={'100%'}
      item
      xs={8}
      md={9}
      py={4}
      sx={{
        direction: lang === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Box px={2}>
        <Swiper
          style={{ direction: 'ltr' }}
          className="mySwiper"
          slidesPerView={3}
          spaceBetween={10}
          modules={[FreeMode]}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            460: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            650: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            770: {
              slidesPerView: 2.5,
              spaceBetween: 10,
            },

            900: {
              slidesPerView: 2.3,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 2.5,
              spaceBetween: 10,
            },
            1500: {
              slidesPerView: 3.4,
              spaceBetween: 10,
            },
          }}
        >
          {data?.map((item) => (
            <SwiperSlide key={item.title}>
              <Box sx={{
                height: {
                  xs: '350px',
                  md: '400px',
                  lg: '553px'
                }, width: '100%',
                'border-radius':'10px',
                overflow: 'hidden',
                my:3,

                boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}  >
                <Card data={item} />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Grid>
  )
}

const Category1 = ({categoryN}) => {
  // const { data, isSuccess, isError, error } =
  //   useGetMostNewiestProductsQuery(undefined)
  const { data, isSuccess, isError, error } =
  useGetAllProductsByCategoryQuery(undefined)
  const [, { language: lang }] = useTranslation()
  const { colors } = ScrollColors;
  const [categories,setCategories] =useState({})

  useEffect(()=>{
    if(isSuccess &&!isError){

      setCategories({...categories,
        products:data?.data[categoryN]?.products,
        category:data?.data[categoryN]?.category
      })
     }
  },[data])
  return (
    <Box
       sx={{
        direction: lang === 'en' ? 'ltr' : 'rtl',
        bgColor: "none",
      }}
       py={data?.data !== undefined ? 3 : 0}
    >
      {isError && error && <ErrorSection error={error} isError={isError} />}
      {isSuccess && !isError && data?.data?.length > 0 && (
        <Grid container>
          {/* Newest title and btn */}
          <Grid
            item
            xs={4}
            md={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <SliderHeader category={categories?.category} colors={colors} />
          </Grid>

          {/* Slider */}
          <Slider data={categories?.products} lang={lang} />
        </Grid>
      )}
    </Box>
  )
}

export default Category1
