import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
} from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  AllProductsGridStyles,
  DepertmentStyles,
  SearchStyles,
} from './category.style.jsx'
import { CiSearch } from 'react-icons/ci'
import CloseIcon from '@mui/icons-material/Close'
import Breadcrumbs from '../../../components/BreadCrumbs/BreadCrumbs.jsx'
import { useLazyGetAllProductsQuery } from '../../../redux/apis/ProductApis'
import { useLazyGetAllAttributesQuery } from '../../../redux/apis/attributeApi.js'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { colors } from './category.style.jsx'
import { filterMenuStyles } from './category.style.jsx'
import RefreshIcon from '@mui/icons-material/Refresh'
import MetaTags from '../../../components/metaTags/MetaTags.jsx'
// import Card8 from '../../../components/Cards/Horizontal Rectangle/strockCustomCard7/index.jsx'
import Card from '../../../components/Cards/Scrolls/Scrolling1/index.jsx'
import Pagination from '@mui/material/Pagination'
import FilterAttributesMenu from '../../../components/FilterAttributesMenu/FilterAttributesMenu.jsx'
// ============FilterSearch=========================
const Search = ({ onChange }) => {
  const [search, setSearch] = useState('')
  const location = useLocation()
  useEffect(() => {
    setSearch('')
  }, [location.pathname])
  const {
    i18n: { language },
  } = useTranslation()
  useEffect(() => {
    const id = setTimeout(() => {
      const s = search.trim()
        ? `keyword[title_en]=${search}&keyword[title_ar]=${search}&keyword[description_en]=${search}&keyword[description_ar]=${search}`
        : ''
      onChange(s)
    }, 500)
    return () => {
      clearTimeout(id)
    }
  }, [search])
  return (
    <Box sx={SearchStyles.BoxSearch}>
      <Box
        component={'input'}
        placeholder={language === 'en' ? 'Search' : 'بحث'}
        sx={SearchStyles.BoxInput}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <CiSearch size={24} />
    </Box>
  )
}
// ============FilterSearch=========================

function AllProductsGrid({ cards }) {
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 3,
      }}
      pt={1}
      width={{ xs: '97%', sm: '100%', md: '100%', lg: '90%' }}
    >
      {cards?.map((card, index) => (
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          xl={4}
          key={index}
          sx={AllProductsGridStyles.CardItem}
        >
          {card}
        </Grid>
      ))}
    </Grid>
  )
}
// ============FilterSearch=========================

// ============================HookCategory====================
const useFetchProductsByCategoryId = (categoryId, subId, query) => {
  const [params, setSearchParams] = useSearchParams()
  const [products, setProducts] = useState([])
  const [paginationData, setPaginationData] = useState({})
  const [getAllProducts, { isLoading }] = useLazyGetAllProductsQuery()
  const pageNumber = params.get('page') || 1
  useEffect(() => {
    if (categoryId && !subId) {
      params.set('category', categoryId)
      params.set('limit', 24)
      params.set('page', pageNumber)

      getAllProducts(
        // `category=${categoryId}&limit=2${query ? `&${query}` : ''}&page=${pageNumber}`
        params.toString().concat(query ? `&${query}` : '')
      )
        .unwrap()
        .then((res) => {
          setPaginationData(res.paginationResult)
          setProducts(res.data)
        })
        .catch(() => {
          setProducts([])
        })
    }
  }, [query, categoryId, subId, pageNumber])
  return {
    categoryProducts: products,
    isLoading,
    paginationData,
    params,
    setSearchParams,
  }
}
// ============================   HookCategory      ====================
export const CategoryPage = (props) => {
  const { categoryId } = useParams()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [filterQuery, setFilterQuery] = useState('')
  const [, { language: lng }] = useTranslation()
  //custom hook
  const {
    categoryProducts,
    isLoading: categoryProductsLoading,
    paginationData,
    params,
    setSearchParams,
  } = useFetchProductsByCategoryId(
    categoryId,
    undefined,
    `${filterQuery ? `&${query}` : query}${
      query ? `&${filterQuery}` : filterQuery
    }`
  )
  const Styles = DepertmentStyles({ props, lng })
  useEffect(() => {
    setData(categoryProducts)
    setLoading(categoryProductsLoading)
  }, [categoryProducts, categoryProductsLoading, categoryId])
  const { totalPages } = paginationData

  const handlPagination = (e, page) => {
    params.set('page', page)
    setSearchParams(params)
  }

  return (
    <>
      {/* Get Category Meta */}
      <MetaTags id={categoryId} />
      <Box
        sx={{
          ...Styles.Stack,
          alignItems: 'center',
          justifyContent: 'flex-start',
          direction: lng === 'en' ? 'ltr' : 'rtl',
          px: {
            md: '20px',
            xs: '5px',
          },
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
        }}
      >
        {/* <Search onChange={(value) => setQuery(value)} /> */}
      </Box>
      <Box sx={Styles.Box}>
        <Breadcrumbs colors={Styles.Breadcrumbs} />
        <Stack direction={'row'} justifyContent={'flex-start'} px={3}>
          <FilterAttributesMenu
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
          />
        </Stack>
        <Stack direction={'column'} sx={Styles.StackDirection}>
          {!loading ? (
            <Stack
              direction={'column'}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                gap: 5,
                flexWrap: 'wrap',
                width: '100%',
                my: 5,
              }}
            >
              <AllProductsGrid
                cards={data?.map((pro, index) => (
                  <Box
                    className="card_category Two_column"
                    sx={{
                      // border: '1px solid red',
                      '& button': {
                        padding: {
                          xs: '13px 30px !important',
                          md: '11px 30px !important',
                        },
                      },
                      height: {
                        xs: '350px',
                        md: '400px',
                        lg: '553px',
                      },
                      width: '100%',
                      'border-radius': '10px',
                      overflow: 'hidden',boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      border: '1px solid #ddd',
                    }}
                  >
                    <Card data={pro} />
                  </Box>
                ))}
              />
              {totalPages > 1 && (
                <Box
                  sx={{
                    direction: 'ltr',
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={Number(params.get('page' || 1))}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlPagination}
                  />
                </Box>
              )}
            </Stack>
          ) : (
            <span className="loader"></span>
          )}
          {!loading && (data?.length < 1 || data?.length === undefined) && (
            <Typography color={'error'} sx={{ m: 5, fontSize: '2rem' }}>
              {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
            </Typography>
          )}
          {/* not fo */}
        </Stack>
      </Box>
    </>
  )
}
