import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import HomePage from '../pages/home/Index'
import backgroundAuth from '../assets/svg/AuthBg.svg'
import authLogo from '../assets/svg/logo.svg'
import ProfilePage from '../pages/profile_2/profile3/Profile1.jsx'
import { CategoriesPage } from '../pages/shop/categoriespage'
import { CategoryPage } from './../pages/shop/Category/index'
import { SubCategoriePage } from '../pages/shop/subcategory'
import ContactNoon7 from '../pages/ContactUs/Noon7/ContactNoon7.jsx'
import Register2 from '../pages/Register/Register2/Register2.jsx'
import Login2 from '../pages/Login/Login2/Login2'
import ForgetPassword from './../pages/ForgetPassword/ForgetPassword'
import Google from '../components/google/Google'
import { SavedProductsPage } from './../pages/savedProducts/index'
import { ShishiSingleProduct } from '../pages/singleProductPages/shishiSingleProduct'
import PaymentMoyasar from '../pages/Payment/PaymentMoyasar/PaymentMoyasar.jsx';
import ThanksPage from '../pages/ThanksPage/ThanksPage.jsx'
import { NoImage } from './../pages/privacies/NoImage/index'
import CheckOutTomoor from '../pages/PaymentPage/Tomoor/CheckOutTomoor'
import { useSelector } from 'react-redux'
import ProtectedRoutes from './ProtectedRoutes'
import { Cart04 } from '../pages/cart/cart4/Cart04.jsx'
import NotificationsPage from '../pages/Notifications/index.jsx'
import BlogsPage from '../pages/blogs/blogs1/Blogs1.jsx'
import SingleBlog1 from '../pages/singleBlog/singleBlog1/SingleBlog1.jsx'
import About2 from '../pages/about/about2/About2.jsx'
import { SubSubCategoryProductsPage } from '../pages/shop/SubSubCategoryProductsPage/Index.jsx'
import NotFoundPage from '../pages/notFound/Index.jsx'
import { SubCategorySubSubsProductsPage } from '../pages/shop/subCategorySubSubs/SubCategorySubSubsProductsPage.jsx'
import { AllSubSubCategoriesPage } from '../pages/shop/AllSubSubCategories/index.jsx'
import { SubSubCategoryById } from '../pages/shop/SubSubCategoryById/index.jsx'
import SearchPage from '../pages/search/SearchPage.jsx'
import SelectPayment from '../pages/Payment/SelectPayment/SelectPayment.jsx'
import ThanksPageTabby from '../pages/ThanksPage/ThanksPageTabby.jsx'
import ThanksPageTamara from '../pages/ThanksPage/ThanksPageTamara.jsx'

import { useEffect } from 'react'
const AppRoutes = ({ isCartDrawer }) => {
  const { currentUser } = useSelector((state) => state)
  const location = useLocation()
  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [location?.pathname])
  const checkAuthentication =
    (currentUser &&
      Object.keys(currentUser).length > 0 &&
      currentUser?.email) ||
    currentUser?.phone
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/products/:id/:name" element={<ShishiSingleProduct />} />

      <Route element={<ProtectedRoutes condition={!checkAuthentication} />}>
        <Route
          path="/sign-in"
          element={<Login2 backgroundImage={backgroundAuth} logo={authLogo} />}
        />
        <Route
          path="/register"
          element={
            <Register2 backgroundImage={backgroundAuth} logo={authLogo} />
          }
        />
      </Route>

      <Route element={<ProtectedRoutes condition={checkAuthentication} />}>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/checkout" element={<CheckOutTomoor />} />
      </Route>
      <Route
        path="/contactUs"
        element={<ContactNoon7 backgroundImag={backgroundAuth} />}
      />
      <Route path="/aboutus" element={<About2 />} />
      <Route path="/notifications" element={<NotificationsPage />} />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/clinics" element={<CategoriesPage />} />
      <Route path="/clinics/:categoryId/:name" element={<CategoryPage />} />
      <Route path="/auth/google/callback" element={<Google />} />
      {!isCartDrawer && <Route path="/cart" element={<Cart04 />} />}
      <Route
        path="/clinics/:categoryId/:subId/:name"
        element={<SubCategoriePage />}
      />
      <Route path="/savedProducts" element={<SavedProductsPage />} />


      <Route path="/select-payment" element={<SelectPayment />} />
      <Route path="/payment-moyasar" element={<PaymentMoyasar />} />
      <Route path="/thankYou" element={<ThanksPage />} />

      <Route path="/tabbyPayment/:type" element={<ThanksPageTabby />} />
      {/* <Route path="/cancel" element={<ThanksPageTabby />} />
      <Route path="/failure" element={<ThanksPageTabby />} /> */}

      <Route path="/tamaraPayment/:type" element={<ThanksPageTamara />} />
      {/* <Route path="/cancel" element={<ThanksPageTamara />} />
      <Route path="/fail" element={<ThanksPageTamara />} /> */}

      <Route path="/policies/:policyType" element={<NoImage />} />
      <Route path="/blogs" element={<BlogsPage />} />
      <Route path="blogs/:blogId/:blogName" element={<SingleBlog1 />} />
      {/* New Rotues for subSubCategories */}
      <Route
        path="/clinics/:categoryItemId/:subId/:subName/subSubCategories"
        element={<SubCategorySubSubsProductsPage />}
      />
      <Route
        path="/clinics/:categoryItemId/:subItemId/:subSubId/:name"
        element={<SubSubCategoryProductsPage />}
      />
      <Route path="/search/:searchedTitle" element={<SearchPage />} />
      <Route
        path="/subSubCategories/:subSubId/:subSubName"
        element={<SubSubCategoryById />}
      />
      <Route path="/subSubCategories" element={<AllSubSubCategoriesPage />} />
      {/*  */}
      <Route path="*" element={<Navigate to="/notfound" replace />} />
      <Route path="/notfound" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
