export const colors = {
  bgColor: '#25CAD2',
  titleColor: '#fff',
  descColor: '#fff',
  subTitleColor: '#fff',
  buttonTextColor: '#fff',
  buttonBgColor: '#D40054',
}

export default {
  gridContainer: {
    backgroundColor: colors.bgColor,
   },
  aboutSection: {
    typography: {
      fontSize: { xs: '27px', sm: '30px', lg: '40px' },
      fontWeight: 'bold',
      textAlign: 'center',
      color: colors.titleColor, textTransform: 'capitalize',
    },
    dangerously: {
      mt: 0.5,
      fontSize: { xs: '18px', md: '22px', lg: '25px' },
      color: colors.subTitleColor,
    },
    flexHeader: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  videoContainer: {
    height: 500,
    video: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
}
