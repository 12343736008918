import {appColors} from "../../constants/colors"

export const ThanksStyle={
    color:{
        titleColor: appColors.secondary.main,
        subTitleColor: '#bf9fea',
        Btn: {
          textColor: 'white',
          bgColor: appColors.secondary.main,
        },
      }
}