import { useLazyGetNotificationsByUserIdQuery } from '../../redux/apis/NotificationsApi'
import { Box, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import NotifyItem from '../../components/Notifications/NotifyItem'
import { useNavigate } from 'react-router-dom'
import { appColors } from '../../constants/colors'
const NotificationsPage = () => {
  const navigate = useNavigate()
  const [, { language: lng }] = useTranslation()
  const [getNots, { isLoading, isError, isSuccess, error }] =
    useLazyGetNotificationsByUserIdQuery()
  const [nots, setNots] = useState([])
  const [selectedNotify, setSelectedNotify] = useState({})
  const notifyId = localStorage.getItem('notifyId')
  const customMoment = time => {
    const custom = moment(time).locale(lng).fromNow()
    return custom
  }

  // Find the index of the third "/"
  const thirdSlashIndex = selectedNotify.link?.indexOf(
    '/',
    selectedNotify.link.indexOf('/', selectedNotify.link.indexOf('/') + 1) + 1
  )

  // Slice the URL after the third "/"
  const slicedURL = selectedNotify.link?.slice(thirdSlashIndex + 1)

  useEffect(() => {
    getNots()
      .unwrap()
      .then(res => {
        console.log('res', res)
        setNots(res?.data)
        setSelectedNotify(res?.data?.find(notify => notify._id === notifyId))
      })
      .catch(err => {
        console.log('err', err)
      })
  }, [])
  return (
    <Box sx={{ bgcolor: '#f0f2f5', py: 5, minHeight: '100vh' }}>
      <Container>
        {isLoading && <span className='loader'></span>}
        {isError && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '80vh',
            }}
          >
            <Typography
              fontSize={'1.5rem'}
              my={10}
              textAlign={'center'}
              color='error'
            >
              {error?.data && error?.data[`error_${lng}`]}
            </Typography>
          </Box>
        )}
        {isSuccess && (
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent={'space-between'}
            rowGap={5}
            sx={{ mt: 10 }}
          >
            <Box
              sx={{
                bgcolor: '#ffffff',
                borderRadius: 4,
                p: 3,
                width: { xs: 1, sm: 0.5 },
                maxHeight: 500,
                overflowY: 'auto',
                scrollbarGutter: 'stable',
                '&::-webkit-scrollbar': {
                  width: '2px',
                  '&:hover': {
                    width: '10px',
                  },
                },
                '&::-webkit-scrollbar-track': {
                  background: '#E2E2E2',
                  borderRadius: 4,
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#9e9e9e',
                  borderRadius: 4,
                },
              }}
            >
              <Typography
                variant='h5'
                sx={{
                  textAlign: lng === 'ar' ? 'right' : 'left',
                  fontWeight: 'bold',
                  fontSize: '25px',
                  mb: 3,
                  color: appColors.secondary.main,
                }}
              >
                {lng === 'en' ? 'Notifications' : 'الاشعارات'}
              </Typography>
              {nots?.map(notify => (
                <Stack
                  sx={{ mb: 2, cursor: 'pointer' }}
                  key={notify._id}
                  onClick={() => {
                    // Create a copy of nots
                    const updatedNots = nots.map(n =>
                      n._id === notify._id ? { ...n, read: true } : n
                    )

                    // Update the state with the modified array
                    setNots(updatedNots)
                    setSelectedNotify({ ...notify, read: true })
                  }}
                >
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <NotifyItem
                      message={notify?.title}
                      createdAt={notify?.createdAt}
                      read={notify?.read}
                      _id={notify?._id}
                    />
                  </Stack>
                </Stack>
              ))}
            </Box>
            <Box
              sx={{
                bgcolor: '#ffffff',
                borderRadius: 2,
                p: 2,
                width: { xs: 1, sm: 0.45 },
                height: { xs: 300, sm: 500 },
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: appColors.secondary.main,
                  fontSize: { xs: '20px', md: '27px' },
                  mb: 2,
                }}
              >
                {lng === 'en' ? 'Selected Notification' : 'الاشعار المحدد'}
              </Typography>
              <Typography variant='h5'>{selectedNotify?.title}</Typography>
              <Box>
                <Typography my={1}>{selectedNotify?.message}</Typography>
                <Typography
                  sx={{ fontSize: '13px', color: '#999999' }}
                >{`${customMoment(selectedNotify?.createdAt)}`}</Typography>
                <Typography
                  onClick={() => {
                    navigate(`/${slicedURL}`)
                  }}
                  sx={{
                    wordBreak: 'break-word',
                    cursor: 'pointer',
                    bgcolor: appColors.primary.main,
                    color: '#fff',
                    width: 'fit-content',
                    my: 2,
                    padding: '5px 10px',
                    borderRadius: '5px',
                    textDecoration: 'none',
                    textAlign: lng === 'ar' ? 'right' : 'left',
                  }}
                >
                  {lng === 'en' ? 'Show Details' : 'عرض التفاصيل بالكامل'}
                </Typography>
              </Box>
            </Box>
          </Stack>
        )}
      </Container>
    </Box>
  )
}

export default NotificationsPage
