import FavoriteIcon from '@mui/icons-material/Favorite'
import { toast } from 'react-toastify'
import { CardMedia, Paper, Box, Stack, Typography } from '@mui/material'
import { imageBaseUrl } from '../../../../constants/baseUrl'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  useAddToSavedProductMutation,
  useGetAllSavedProductsQuery,
} from '../../../../redux/apis/SavedProductApi'

import styles from './card.styles'

const FavoriteIconCard = ({ data, lng, toFavorite, proInFav }) => {
  return (
    <FavoriteIcon
      sx={{
        ...styles.favIcon,
        color: proInFav ? 'tomato' : 'transparent',
        strokeWidth: proInFav ? 0 : 1,
        margin: '0px 10px',
        stroke:'thistle'
      }}
      onClick={(e) => {
        e.stopPropagation(),
          toFavorite(data?._id)
            .unwrap()
            .then((res) => {
              toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
            })
            .catch((e) =>
              toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
            )
      }}
    />
  )
}

const CardContent = ({ data, lng, proInFav, toFavorite }) => {
  return (
    <Stack sx={{
      ...styles.contentContainer,
      overflow: 'hidden',
     }}>
      <Box sx={{
        marginRight:'10px',
        maxHeight:'63px'
      }}>
        <Typography sx={{
          px:lng==="en"?'10px':"0",
            color:'#A3A3A3',
          fontSize:{
            xs:'16px',
            md:'12px',
            lg:'19px'
          },
          fontStyle:'normal !important'
        }} >
          {data[`title_${lng === 'en' ? 'en' : 'ar'}`] }
         
        </Typography>
        {/* <Box
          className="desc"
          sx={{
            margin:'0px !Important',
            color:'#A3A3A3',
             '& ul': {
              textAlign: `${lng === 'en' ? 'left' : 'right'} !important`,
              margin: '0px !Important',
            },
            '& ol': {
              textAlign: `${lng === 'en' ? 'left' : 'right'} !important`,
              margin: '0px !Important',
            },
            '& > p': {
              fontSize: {
                xs: '20px',
                margin: '0px !Important',
              },
            },
            '& > *': {
              fontSize: {
                xs: '10px',
                md: '12px',
                lg: '1.5rem',
              },
              "& > br":{
                display:'none !important'
              },
              " > * ":{
                margin:'0px !important'
              },
              " & > *":{
                margin:'0px !important'
              },
              px: lng === 'en' ? '10px' : '0',
               
            },
            height: 'fit-content',
          }}
          dangerouslySetInnerHTML={{
            __html:
              data[`description_${lng === 'en' ? 'en' : 'ar'}`]?.split(' ')
                .length > 3
                ? `${data[`description_${lng === 'en' ? 'en' : 'ar'}`].slice(0,50)?.split(' ').join(' ')} ...`
                : data[`description_${lng === 'en' ? 'en' : 'ar'}`].slice(0,50),
          }}
        /> */}
      </Box>
      <FavoriteIconCard
        data={data}
        proInFav={proInFav}
        toFavorite={toFavorite}
        lng={lng}
      />
    </Stack>
  )
}

//⭐ Parent
const Card = (props) => {
  const [, { language: lng }] = useTranslation()
  const nav = useNavigate()
  const [toFavorite] = useAddToSavedProductMutation()
  const { data: favPros } = useGetAllSavedProductsQuery(undefined)
  const proInFav = favPros?.data.favourite.find(
    (f) => f._id === props?.data._id
  )

  return (
    <Paper
      sx={{
        ...styles.cardPaper,
        direction: `${lng === 'en' ? 'ltr' : 'rtl'}`,
        padding: '0px',boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
      }}
    >
      {props?.data?.images && props?.data?.images[0] && (
        <CardMedia
          component={'img'}
          src={imageBaseUrl + props?.data?.images[0]}
          onClick={() =>
            nav(
              `/products/${props?.data?._id}/${props?.data?.title_en.replace(
                /\s/g,
                '-'
              )}`
            )
          }
          sx={styles.cardImg}
        />
      )}

      <CardContent
        data={props?.data}
        lng={lng}
        proInFav={proInFav}
        toFavorite={toFavorite}
      />
    </Paper>
  )
}

export default Card
