import React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import Rating from './Rating'
import DeleteIcon from '@mui/icons-material/Delete'
import { imageBaseUrl } from '../../constants/baseUrl'
import { useSelector } from 'react-redux'
import { useRemoveCommentFromProductByUserMutation } from '../../redux/apis/commentApi'
import { toast } from 'react-toastify'

export default function ReviewCard({ rev, user }) {
  const [, { language: lang }] = useTranslation()
  const { currentUser } = useSelector((state) => state)
  const [removeCommentFromProductByUser] =
    useRemoveCommentFromProductByUserMutation()
  const handleRemoveUserReview = () => {
    removeCommentFromProductByUser(rev._id)
      .unwrap()
      .then((res) => {
        toast.success(res[`success_${lang}`])
      })
      .catch((err) => {
        toast.error(err.data[`error_${lang}`])
      })
  }
  console.log('a7a  ===> rev', rev)
  console.log('a7a  ===> user', user)
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 2,
        p: 3,
        bgcolor: 'none',

        my: '0.5px',
        bgcolor: 'white',
        position: 'relative',
      }}
    >
      <Typography
        component="span"
        sx={{
          fontWeight: 'bold',

          fontSize: {
            lg: '18px',
            md: '16px',
            xs: '14px',
          },
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Avatar src={imageBaseUrl + rev.user.image} />
        {/* {lang === 'en' ? 'Overall Rating' : 'التقييم العام'} */}
        <span>
          {rev.user.name
            ? rev.user.name
            : rev.user.registrationType === 'email'
            ? rev.user.email
            : rev.user.phone}
        </span>
      </Typography>
      <Stack
        sx={{
          '& .react-stars': {
            flexDirection: 'column',
            display: 'flex',
            gap: 1,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: 'auto',
          }}
        >
          <Rating
            disable={true}
            totalRating={rev?.rating ? rev?.rating : 0}
            disabled
          />
        </Box>

        {!rev?.rating ? (
          <>{lang === 'en' ? "Ther's No Rating" : 'لم يتم التقييم بعد'}</>
        ) : null}
      </Stack>
      <Stack sx={{ flexDirection: 'column', display: 'flex' }}>
        <Typography
          component="span"
          sx={{
            my: 2,
            wordBreak: 'break-all',
            fontSize: {
              lg: '16px',
              md: '14px',
              xs: '12px',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: rev?.comment,
          }}
        />
        {/* Review user */}

        <Typography
          component="span"
          sx={{
            fontSize: {
              lg: '15px',
              md: '13px',
              xs: '11px',
            },
          }}
        >
          {user?.name}
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: {
              lg: '15px',
              md: '13px',
              xs: '11px',
            },
            color: '#a59d9d',
          }}
        >
          {new Date(rev?.createdAt).toDateString()}
        </Typography>
        {currentUser?._id === rev.user._id && (
          <Button
            sx={{
              minWidth: 0,
              width: 150,
              border: 1,
              fontFamily: `Noto Kufi Arabic !important`,
              mt: '3px',
            }}
            onClick={handleRemoveUserReview}
          >
            {lang === 'en' ? 'remove my review' : 'حذف تقيمى'}
          </Button>
        )}
      </Stack>
    </Box>
    // <></>
  )
}
