import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import RatingModal from './ReviewModalAdd'
import { useTranslation } from 'react-i18next'
import { useGetProductCommentsQuery } from '../../redux/apis/commentApi'
import './style.css'
import ReviewCard from './ReviewCard'
import { useSelector } from 'react-redux'
import { Reviews } from '@mui/icons-material'

export default function ProductReviews({ productId, product }) {
  const [open, setOpen] = useState(false)
  const [, { language: lang }] = useTranslation()
  const [countReviews, setcountReviews] = useState(0)
  const { currentUser } = useSelector((state) => state)
  const [, { language }] = useTranslation()
  const [canReview, setCanReview] = useState(false)
  const [isReviewYet, setIsReviewedYet] = useState(false)

  const { data, isLoading, isError, isSuccess } = useGetProductCommentsQuery(
    `${productId}?limit=1000&populate=user`
  )
  // const {
  //   uniqueProducts
  // } = currentUser

  console.log(data, 'currentuserdsasda')
  useMemo(() => {
    if (!isError && isSuccess) {
      setcountReviews(data?.data?.length)
      console.log(data?.data?.length, 'sdalength')
    }
  }, [data])

  useMemo(() => {
    if (
      currentUser &&
      currentUser?.uniqueProducts !== undefined &&
      currentUser?.uniqueProducts.length
    ) {
      const isReview = currentUser?.uniqueProducts?.includes(product?._id)
      console.log(isReview, 'isReview')
      setCanReview(isReview)
    }
  }, [productId, product, currentUser])
  useMemo(() => {
    if (
      currentUser &&
      typeof currentUser === 'object' &&
      Object.keys(currentUser).length
    ) {
      const IsReviewd = data?.data?.some((review) => {
        console.log(review?.user, currentUser?._id)
        return review?.user === currentUser?._id
      })
      if (IsReviewd) {
        console.log(IsReviewd, 'IsReviewd')
        setIsReviewedYet(true)
        return
      }
    }
  }, [data, productId, currentUser, Reviews])
  console.log(data, 'data,sadsdadas')
  const handleIncreaseCountReviews = () => {
    setcountReviews((prev) =>
      prev + 5 <= data?.length ? prev + 5 : data?.length
    )
  }

  return (
    <>
      <Box
        sx={{
          mb: 5,
          padding: '10px',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {data?.length ? (
          <>
            <Box
              sx={{
                display: 'grid',

                bgcolor: 'none',
                width: 0.9,
                mx: 'auto',
                gap: '10px',
                // bgcolor: '#f1f1f1',
                bgcolor: 'transparent',
              }}
            >
              {[...Array(countReviews)].map((star, index) => {
                return <ReviewCard rev={data?.data[index]} key={index + 1} />
              })}
            </Box>
            {countReviews < data.length ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 0.9,
                  mx: 'auto',
                  bgcolor: 'none',
                  marginTop: '20px',
                }}
              >
                <Button
                  type="button"
                  onClick={handleIncreaseCountReviews}
                  sx={{
                    color: '#00f',
                    borderRadius: '20px',
                    width: 'auto',
                    fontSize: '18px',
                    mx: 'auto',
                    p: 2,
                    my: 2,
                    marginTop: '20px',
                  }}
                >
                  {lang === 'en' ? 'View more' : 'المزيد'}
                </Button>
              </Box>
            ) : null}
          </>
        ) : (
          <>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textTransform: 'capatalize',
              }}
            >
              {language === 'en'
                ? "There 's No Reviews  Yet"
                : ' لا توجد مراجعات بعد'}
            </Typography>
          </>
        )}
        {/* {
  console.log(data?.data?.some(review=>{
   return  review?.user===currentUser?._id
  }),'useruser',data?.data,currentUser)
} */}
        {console.log(canReview, !isReviewYet, 'dsasdasda12213')}
        {canReview && !isReviewYet ? (
          <Button
            sx={{
              padding: '10px',
              margin: ' 0px 10px',
              border: '1px solid #1dcad3',
              color: ' #1dcad3',
              ' border-radius': ' 0px',
              margin: '20px auto',
            }}
            onClick={() => setOpen(!open)}
          >
            {language === 'en' ? 'Add review' : ' أضف مراجعه'}
          </Button>
        ) : null}
      </Box>

      <RatingModal
        open={open}
        setOpen={setOpen}
        productAssets={{
          rating: product.rating,
          id: product._id,
        }}
      />
    </>
  )
}
