import React, { lazy, Suspense } from 'react'
import HomeNewest from '../../components/Home/Newest/Scrolling1'
import AboutVideoButtton from '../../components/Home/About/AboutVideoButtton'
import HomeMostSelling from '../../components/Home/MostSeller/Collection2'
import HeroSolid4Normal from '../../components/Home/HeroSection/HeroSolid/Normal/HeroSolid4/HeroSolid4Normal'
import Category1 from '../../components/newSlidesComponents/category1/Scrolling1'
import HomeBlogsSlider from '../../components/newSlidesComponents/blogs/Scrolling1'
import Hero4Normal from '../../components/Home/HeroSection/HeroSection/HeroBackground/Normal/Hero4/Hero4Normal'
import Collection1 from '../../components/Home/MostSeller/collection1'
const AnalyticsMetaTags = lazy(() =>
  import('../../components/analyticsMetaTags/AnalyticsMetaTags')
)
const Index = () => {
  return (
    <>
      <Hero4Normal />
      <Collection1 />
      {/* <HomeNewest /> */}
      <Category1 categoryN={1} />
      <AboutVideoButtton />
      <Category1 categoryN={0} />
      <HomeMostSelling />
      <HomeBlogsSlider />
      <Suspense fallback={<div>Loading...</div>}>
        <AnalyticsMetaTags />
      </Suspense>
    </>
  )
}

export default Index
