import FavoriteIcon from '@mui/icons-material/Favorite'
import { toast } from 'react-toastify'
import { CardMedia, Paper, Box, Stack, Typography, Button } from '@mui/material'
import { imageBaseUrl } from '../../../../constants/baseUrl'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './index.css'
import {
  useAddToSavedProductMutation,
  useGetAllSavedProductsQuery,
} from '../../../../redux/apis/SavedProductApi'

import {
  useAddToCartMutation,
  useGetAllCartsQuery,
} from '../../../../redux/apis/cartApi'
import styles from './card.styles'
import { CardColors } from './card.colors'

const FavoriteIconCard = ({ data, lng, toFavorite, proInFav }) => {
  return (
    <FavoriteIcon
      sx={{
        color: proInFav ? 'red' : 'transparent',
        fontSize: { xs: '1rem', md: '2rem', lg: '3rem' },
        cursor: 'pointer',
        stroke: 'ButtonHighlight',
      }}
      onClick={(e) => {
        e.stopPropagation(),
          toFavorite(data._id)
            .unwrap()
            .then((res) => {
              toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
            })
            .catch((e) =>
              toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
            )
      }}
    />
  )
}

const CardContent = ({
  data,
  lng,
  productInCart,
  addToCart,
  cartData,
  cardLoad,
  toFavorite,
  proInFav,
}) => {
  const nav = useNavigate()
  return (
    <Stack sx={{ ...styles.contentContainer }} className="content_container">
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack>
          <Typography
            sx={{
              ...styles.titleStyle,
              textAlign: lng === 'en' ? 'left' : 'right',
              'text-shadow': ' 8 8 black',
            }}
          >
            {data[`title_${lng === 'en' ? 'en' : 'ar'}`]}
          </Typography>
          <Box
            className="desc"
            sx={{
              ...styles.descStyle,
              textAlign: lng === 'en' ? 'left' : 'right',
              'text-shadow': ' 8 8 black',
            }}
            dangerouslySetInnerHTML={{
              __html:
                data[`description_${lng === 'en' ? 'en' : 'ar'}`]?.split(' ')
                  .length > 3
                  ? `${data[`description_${lng === 'en' ? 'en' : 'ar'}`]
                      ?.split(' ')
                      .slice(0, 3)
                      .join(' ')} ...`
                  : data[`description_${lng === 'en' ? 'en' : 'ar'}`],
            }}
          />
        </Stack>

        <FavoriteIconCard
          proInFav={proInFav}
          toFavorite={toFavorite}
          data={data}
          lng={lng}
        />
      </Stack>
      <Button
        sx={styles.Button}
        onClick={() => {
          nav(`/products/${data?._id}/${data?.title_en.replace(/\s/g, '-')}`)
        }}
      >
        {lng === 'en' ? 'see More' : 'رؤيه المزيد'}
      </Button>
    </Stack>
  )
}

// console.log(data,'cartData')
// if(data?.paymentType==="both"){
//   nav( `/products/${data?._id}/${data?.title_en.replace(
//     /\s/g,
//     '-'
//   )}`)
//   return
// }

// addToCart({ ...cartData,
//   paymentType: data?.paymentType,})
// .unwrap()
// .then((res) =>
//   toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
// )
// .catch((e) =>
//   toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
// )
// }
// ⭐ Parent
const Card = (props) => {
  const [, { language: lng }] = useTranslation()
  const nav = useNavigate()
  const [toFavorite] = useAddToSavedProductMutation()
  const { data: favPros } = useGetAllSavedProductsQuery(undefined)
  const proInFav = favPros?.data.favourite.find(
    (f) => f._id === props?.data._id
  )
  const [addToCart, { isLoading: cardLoad }] = useAddToCartMutation()
  const { data: cartItems, error } = useGetAllCartsQuery(undefined)

  const cartData = {
    quantity: 1,
    properties: [],
    id: props?.data._id,
  }

  const productInCart =
    !error &&
    cartItems?.data[
      props?.data?.paymentType === 'cash' ? 'cashItems' : 'onlineItems'
    ]?.items?.find(
      (eachProduct) => eachProduct?.product?._id === props?.data?._id
    )

  return (
    <Paper
      sx={{
        ...styles.cardPaper,
        direction: `${lng === 'en' ? 'ltr' : 'rtl'}`,
        // backgroundImage: `url(${imageBaseUrl + props?.data?.images[0]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: 'min-content',boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
        aspectRatio:"1/1"
      }}
      className="card_paper"
    >
      {props?.data?.images && props?.data?.images[0] ? (
        <CardMedia
          component={'img'}
          src={`${imageBaseUrl + props?.data?.images[0]}`}
          onClick={() =>
            nav(
              `/products/${props?.data?._id}/${props?.data?.title_en.replace(
                /\s/g,
                '-'
              )}`
            )
          }
          sx={{ ...styles.cardImg, objectFit: 'contain' ,aspectRatio:"1/1"}}
        />
      ) : null}
      <CardContent
        data={props?.data}
        productInCart={productInCart}
        addToCart={addToCart}
        cartData={cartData}
        lng={lng}
        cardLoad={cardLoad}
        proInFav={proInFav}
        toFavorite={toFavorite}
      />
    </Paper>
  )
}

export default Card
