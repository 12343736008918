import {
  Box,
  Button,
  Menu,
  Accordion,
  Stack,
  Typography,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Grid,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import 'swiper/css'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { DepertmentStyles } from './categoriespage.style'
import { CiSearch } from 'react-icons/ci'
// import Card7 from '../../../components/Cards/Horizontal Rectangle/StrokeCard7/index.jsx'
import Breadcrumbs from '../../../components/BreadCrumbs/BreadCrumbs.jsx'
import { useGetAllProductsByCategoryQuery } from '../../../redux/apis/ProductApis.js'
import Card from '../../../components/Cards/Scrolls/Scrolling1/index.jsx'
import { useLazyGetAllAttributesQuery } from '../../../redux/apis/attributeApi.js'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import {
  AllProductsGridStyles,
  colors,
  filterMenuStyles,
} from '../Category/category.style.jsx'
import RefreshIcon from '@mui/icons-material/Refresh'
import CloseIcon from '@mui/icons-material/Close'
import FilterAttributesMenu from '../../../components/FilterAttributesMenu/FilterAttributesMenu.jsx'

//===============================|| searchFilter ||===================================
//===============================|| categorySwipper ||===================================
function AllProductsGrid({ cards }) {
  const lengthD = (length) => {
    if (length < 3) {
      return 4
    } else {
      return 3
    }
  }
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 5,
      }}
      pt={1}
      width={{ xs: '97%', sm: '100%', md: '100%', lg: '100%' }}
    >
      {cards?.map((card, index) => (
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          xl={3}
          key={index}
          sx={AllProductsGridStyles.CardItem}
        >
          {card}
        </Grid>
      ))}
    </Grid>
  )
}
const Search = ({ onChange }) => {
  const [search, setSearch] = useState('')
  const location = useLocation()

  useEffect(() => {
    setSearch('')
  }, [location.pathname])
  const {
    i18n: { language },
  } = useTranslation()
  useEffect(() => {
    const id = setTimeout(() => {
      const s = search.trim()
        ? `keyword[title_en]=${search}&keyword[title_ar]=${search}&keyword[description_en]=${search}&keyword[description_ar]=${search}`
        : ''
      onChange(s)
    }, 500)
    return () => {
      clearTimeout(id)
    }
  }, [search])
  return (
    <Box
      sx={{
        width: {
          md: '70%',
          xs: 1,
        },
        borderRadius: '0.5rem',
        display: 'flex',
        border: `1px solid #333`,
        alignItems: 'center',
        padding: '0.5rem',
        justifyContent: 'space-between',
      }}
    >
      <Box
        component={'input'}
        placeholder={language === 'en' ? 'Search' : 'بحث'}
        sx={{
          width: '100%',
          height: '100%',
          border: `none`,
          outline: 'none',
          fontSize: '1rem',
          fontWeight: 'bold',
          zIndex: 2,
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <CiSearch size={24} />
    </Box>
  )
}
//===============================|| searchFilter ||===================================

//===============================|| categorySwipper ||===================================

export const CategoriesPage = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [, { language: lng }] = useTranslation()
  const styles = DepertmentStyles(lng)
  const nav = useNavigate()
  const [filterQuery, setFilterQuery] = useState('')

  const { data: allProducts, isLoading: allProductsLoading } =
    useGetAllProductsByCategoryQuery(
      `${filterQuery ? `&${query}` : query}${
        query ? `&${filterQuery}` : filterQuery
      }`
    )

  useEffect(() => {
    setData(allProducts?.data)
    setLoading(allProductsLoading)
  }, [allProducts, allProductsLoading, query])
  const Styles = DepertmentStyles({ lng })

  return (
    <>
      <Box
        sx={{
          ...styles.Box,
          paddingLeft: '0px',
          paddingRight: '0px',
        }}
      >
        <Breadcrumbs colors={styles.Breadcrumbs} />
        <Box
          sx={{
            ...styles.Stack,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            direction: lng === 'en' ? 'ltr' : 'rtl',
            px: {
              md: '0px',
              xs: '5px',
            },
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          }}
        >
          {/* <Search onChange={(value) => setQuery(value)} /> */}

          <FilterAttributesMenu
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
          />
        </Box>
        <Stack sx={styles.StackDirection}>
          {!loading ? (
            <>
              {console.log(allProducts?.data, 'allProducts?.data')}
              {data?.map((item) => (
                <>
                  <Typography
                    sx={{
                      ...styles.Typography,
                      textAlign: lng === 'en' ? 'left' : 'right',
                    }}
                    onClick={() =>
                      nav(
                        `/clinics/${
                          item.category.id
                        }/${item?.category.name_en.replace(/\s/g, '')}`
                      )
                    }
                  >
                    {item.category[`name_${lng === 'en' ? 'en' : 'ar'}`]}
                  </Typography>
                  {
                    <Stack
                      direction={'column'}
                      sx={Styles.AllProductsGridContainer}
                    >
                      <AllProductsGrid
                        cards={item?.products
                          .slice(0, 12)
                          ?.map((pro, index) => (
                            <Box
                              className="card_category Two_column"
                              sx={{
                                '& button': {
                                  padding: {
                                    xs: '13px 30px !important',
                                    md: '11px 30px !important',
                                  },
                                },

                                '& #cardStyle': {
                                  width: '100% !important',
                                },
                                '& #cardStyle img': {
                                  width: '100%',
                                },
                                height: {
                                  xs: '350px',
                                  md: '400px',
                                  lg: '553px',
                                },
                                width: '100%',

                                'border-radius': '10px',
                                overflow: 'hidden',boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                border: '1px solid #ddd',
                              }}
                            >
                              <Card data={pro} />
                            </Box>
                          ))}
                      />
                    </Stack>
                  }
                  {item?.products?.length >= 12 ? (
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        textAlign: 'right',
                        marginLeft: lng === 'ar' ? 'auto' : 'none',
                        marginRight: lng === 'en' ? 'auto' : 'none',
                      }}
                      onClick={() =>
                       nav(
                          `/clinics/${
                            item.category.id
                          }/${item?.category.name_en.replace(/\s/g, '')}`
                        )}
                    >
                      {lng === 'en' ? 'See More' : 'رؤيه المزيد'}
                    </Typography>
                  ) : null}
                </>
              ))}
            </>
          ) : (
            <span className="loader"></span>
          )}
          {!loading && (data?.length < 1 || data?.length === undefined) && (
            <Typography color={'error'} sx={{ m: 5, fontSize: '2rem' }}>
              {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
            </Typography>
          )}
          {/* not fo */}
        </Stack>
      </Box>
    </>
  )
}
