import React from 'react'
import {
  Box,
  Grid,
  Stack,
  Typography,
  Container,
  FormLabel,
  Button,
  InputBase,
  CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ContactNoon7Style } from './ContactNoon7Style'
import { useFormik } from 'formik'
import { useContactMutation } from '../../../redux/apis/contactsApis'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { toast } from 'react-toastify'
import * as yup from 'yup'

// ===========================|| FORMIK DATA AND VALIDATION||=========================== //
const useContact = () => {
  const [_, { language: lang }] = useTranslation()
  const contactData = {
    name: '',
    email: '',
    phone: '',
    message: '',
    contactType: '',
  }
  const contactValidation = yup.object({
    name: yup
      .string()
      .required(lang === 'en' ? 'Name is required' : 'الاسم مطلوب'),
    email: yup
      .string()
      .email(lang === 'en' ? 'Email is invalid' : 'البريد الالكتروني غير صحيح')
      .required(
        lang === 'en' ? 'Email is required' : 'البريد الالكتروني مطلوب'
      ),
    phone: yup
      .string()
      .matches(
        /^\+?[0-9]+$/,
        lang === 'en' ? 'Invalid phone number' : 'رقم الهاتف غير صالح'
      )
      .required(lang === 'en' ? 'Phone is required' : 'رقم الهاتف مطلوب'),
    message: yup
      .string()
      .required(lang === 'en' ? 'Message is required' : 'اكتب رسالتك مطلوبة'),
    contactType: yup
      .string()
      .required(lang === 'en' ? 'Select Contact Type' : 'اختار نوع التواصل')
      .ensure()
      .oneOf(
        ['complaints', 'suggestions', 'customerService'],
        lang === 'ar'
          ? 'يجب أن يكون نوع الاتصال أحد القيم التالية: شكاوي، اقتراحات، خدمة العملاء'
          : 'ContactType must be one of the following values: complaints, suggestions, customerService'
      ),
  })

  return { contactData, contactValidation }
}

// ===========================|| Connections ||=========================== //
const Connections = [
  {
    title: 'phone',
    text: '0539394004',
    icon: (
      <LocalPhoneIcon
        sx={{
          fontSize: { xs: '30px', md: '28px', lg: '35px' },
        }}
      />
    ),
  },
  {
    title: 'email',
    text: 'marketingbiolife2020@gmail.com',
    icon: (
      <EmailIcon
        sx={{
          fontSize: { xs: '30px', md: '28px', lg: '35px' },
        }}
      />
    ),
  },
  {
    title: 'location',
    text: 'طريق الأمير سلطان - برج المرجانة - الدور الخامس جدة - المملكة العربية السعودية',
    icon: (
      <LocationOnIcon
        sx={{
          fontSize: { xs: '30px', md: '28px', lg: '35px' },
        }}
      />
    ),
  },
]

//=========================================|| SERVICES DATA ||=========================================//
export const Services = () => {
  const [_, { language: lang }] = useTranslation()
  const ourServices = [
    {
      title: lang === 'en' ? 'Policy and privacy' : 'سياسة الخصوصية',
      link: '/privacyPolicy',
    },
    {
      title: lang === 'en' ? 'About Us' : 'من نحن',
      link: '/aboutUs',
    },
    {
      title: lang === 'en' ? 'Contact Us' : 'تواصل معنا',
      link: '/contactUs',
    },
  ]

  return [ourServices]
}
// ===============================================================================

// ===========================|| Connections ICONS ||=========================== //
const ConnectionsContainer = (props) => {
  return (
    <Box>
      {props.title === 'phone' && (
        <LocalPhoneIcon
          sx={{
            fontSize: { xs: '30px', md: '28px', lg: '35px' },
            color: props.iconColors?.color ? props.iconColors.color : 'gray',
          }}
        />
      )}
      {props.title === 'email' && (
        <EmailIcon
          sx={{
            fontSize: { xs: '30px', md: '28px', lg: '35px' },
            color: props.iconColors?.color ? props.iconColors.color : 'gray',
          }}
        />
      )}
      {props.title === 'location' && (
        <LocationOnIcon
          sx={{
            fontSize: { xs: '30px', md: '28px', lg: '35px' },
            color: props.iconColors?.color ? props.iconColors.color : 'gray',
          }}
        />
      )}
    </Box>
  )
}

// ===========================|| CONTACT TEXT INPUT ||=========================== //
const ContactTextInput = (props) => {
  return (
    <Box>
      <Container>
        <Box mt={2} mb={2}>
          <FormLabel
            sx={{
              fontSize: {
                md: '20px',
                xs: '15px',
              },
              fontWeight: 'bold',
              color: props.color?.textColor ? props.color.textColor : 'black',
            }}
          >
            {props.label}
          </FormLabel>
        </Box>

        <InputBase
          type="text"
          value={props.value}
          name={props.name}
          sx={{
            bgcolor: 'transparent !important',
            width: '100%',
            p: {
              md: '13px 20px',
              xs: '8px 15px',
            },
            color: props.color?.textColor ? props.color.textColor : 'black',
            border:
              props.error && props.touched
                ? `2px solid red`
                : `2px solid  ${
                    props.color?.borderColor ? props.color?.borderColor : 'gray'
                  }`,

            '& input::placeholder': {
              fontSize: '20px',
            },
            borderRadius: props.raduis ? '50px' : '10px',
          }}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
        <Typography
          sx={{
            fontSize: '12px',
            color: 'red',
          }}
        >
          {props.error && props.touched ? props.error : undefined}
        </Typography>
      </Container>
    </Box>
  )
}

// ===========================||CONTACT FORM||=========================== //
const ContactForm = (props) => {
  const [_, { language: lang }] = useTranslation()
  const [contact, { isLoading }] = useContactMutation()
  const { contactData, contactValidation } = useContact()

  const formik = useFormik({
    initialValues: contactData,
    validationSchema: contactValidation,
    onSubmit: (values) => {
      contact(values)
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lang}`])
          formik.resetForm()
        })
        .catch((err) => {
          toast.error(err?.data[`error_${lang}`])
        })
    },
  })

  return (
    <Box>
      <Box
        sx={{
          boxShadow: props.width
            ? '0px 0px 4px 0px rgba(0,0,0,0.3)'
            : undefined,
          direction: lang === 'en' ? 'ltr' : 'rtl',
          bgcolor: props.background ? props.background : 'transparent',
          width: props.width ? props.width : { xs: '100%', md: '90%' },
          mx: props.width ? 'none' : 'auto',
          mb: 2,
          borderRadius: { xs: '0', md: '10px' },
          pt: 2,
          pb: 3.5,
          mt: { xs: 0, md: 2 },
        }}
      >
        <Box px={4}>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <ContactTextInput
                value={formik.values.name}
                name={'name'}
                error={formik.errors.name}
                touched={formik.touched.name}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                label={lang === 'en' ? 'Name' : 'الاسم'}
                raduis={props.borderRadiusInput}
                color={props.color}
              />
            </Box>

            <Box>
              <ContactTextInput
                value={formik.values.email}
                name={'email'}
                error={formik.errors.email}
                touched={formik.touched.email}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                label={lang === 'en' ? 'Email' : 'البريد الالكتروني'}
                raduis={props.borderRadiusInput}
                color={props.color}
              />
            </Box>
            <Box>
              <ContactTextInput
                value={formik.values.phone}
                name={'phone'}
                error={formik.errors.phone}
                touched={formik.touched.phone}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                label={lang === 'en' ? 'Phone' : 'رقم الهاتف'}
                raduis={props.borderRadiusInput}
                color={props.color}
              />
            </Box>
            <Container>
              {/* type of connections */}
              <Box>
                <Box my={2}>
                  <FormLabel
                    sx={{
                      fontSize: {
                        md: '20px',
                        xs: '15px',
                      },
                      fontWeight: 'bold',
                      color: props.color?.textColor
                        ? props.color.textColor
                        : 'black',
                    }}
                  >
                    {lang === 'en' ? 'Contact Type' : 'نوع التواصل'}
                  </FormLabel>
                </Box>
                <select
                  value={formik.values.contactType}
                  name="contactType"
                  style={{
                    backgroundColor: 'transparent',
                    width: '100%',
                    borderRadius: props.borderRadiusInput ? '40px' : '10px',
                    p: {
                      md: '13px 20px',
                      xs: '8px 15px',
                    },
                    border:
                      formik.errors.contactType && formik.touched.contactType
                        ? `2px solid red `
                        : `2px solid ${
                            props.color?.borderColor
                              ? props.color?.borderColor
                              : 'black'
                          }`,
                    fontSize: '20px',
                    color: formik.values.contactType
                      ? props.color?.textColor
                      : '#333',
                    outline: 'none',
                    // WebkitAppearance: 'none',
                    // MozAppearance: 'none',
                    // appearance: 'none',
                    height: '65px',
                    padding: '10px 20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="select one" hidden selected>
                    {lang === 'en'
                      ? 'Select Contact Type'
                      : 'أختار نوع التواصل'}
                  </option>
                  <option
                    value="complaints"
                    style={{
                      color: props.color?.textColor
                        ? props.color.textColor
                        : 'black',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    {lang === 'en' ? 'Complaints' : 'الشكاوي'}
                  </option>
                  <option
                    value="suggestions"
                    style={{
                      color: props.color?.textColor
                        ? props.color.textColor
                        : 'black',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    {lang === 'en' ? 'Suggestions' : 'الاقتراحات'}
                  </option>
                  <option
                    value="customerService"
                    style={{
                      color: props.color?.textColor
                        ? props.color.textColor
                        : 'black',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    {lang === 'en' ? 'Customer Service' : 'خدمة العملاء'}
                  </option>
                </select>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: 'red',
                  }}
                >
                  {formik.errors.contactType && formik.touched.contactType
                    ? formik.errors.contactType
                    : undefined}
                </Typography>
              </Box>

              {/* Message */}

              <Box sx={{ mt: 4 }}>
                <Box my={2}>
                  <FormLabel
                    sx={{
                      fontSize: {
                        md: '20px',
                        xs: '15px',
                      },
                      fontWeight: 'bold',
                      color: props.color?.textColor
                        ? props.color.textColor
                        : 'black',
                    }}
                  >
                    {lang === 'en' ? 'Message' : 'اكتب رسالتك'}
                  </FormLabel>
                </Box>
                <textarea
                  value={formik.values.message}
                  name="message"
                  style={{
                    width: '100%',
                    padding: '12px 16px',
                    height: '200px',
                    backgroundColor: 'transparent',
                    fontSize: '16px',
                    borderRadius: props.borderRadiusInput ? '20px' : '10px',
                    border:
                      formik.errors.message && formik.touched.message
                        ? '2px solid red'
                        : `2px solid ${
                            props.color?.borderColor
                              ? props.color?.borderColor
                              : '#333'
                          }`,
                    outline: 'none',
                    resize: 'none',
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <Typography
                  sx={{
                    fontSize: '12px',
                    color: 'red',
                  }}
                >
                  {formik.errors.message && formik.touched.message
                    ? formik.errors.message
                    : undefined}
                </Typography>
              </Box>
            </Container>
            {/* Button */}
            <Box
              sx={{
                mt: 4,
                textAlign: 'center',
              }}
            >
              <Button
                disabled={isLoading}
                variant="contained"
                sx={{
                  backgroundColor: props.button?.backgroundColor
                    ? `${props.button?.backgroundColor} !important`
                    : 'black !important',
                  color: props.button?.color ? props.button?.color : 'white',
                  fontWeight: 'bold',
                  fontSize: { xs: '20px', md: '26px' },
                  transition: 'all 0.3s',
                  px: {
                    md: '60px',
                    xs: '25px',
                  },
                  py: {
                    md: '8px',
                    xs: '6.5px',
                  },
                  '&:active': {
                    transform: 'scale(0.9)',
                  },
                  borderRadius: props.borderRadiusInput ? '50px' : '10px',
                }}
                type="submit"
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress
                      sx={{
                        color: props.button?.color || 'white',
                      }}
                    />
                  </Box>
                ) : lang === 'en' ? (
                  'Send'
                ) : (
                  'ارسال'
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  )
}

// ===========================|| CONTACT US - NOON7 ||=========================== //
const ContactNoon7 = () => {
  const [_, { language: lang }] = useTranslation()
  const [ourServices] = Services()
  const navigate = useNavigate()
  const handelClick = (link) => {
    navigate(link)
  }
  const handelConnections = (item) => {
    item.title === 'phone' && window.open(`tel:${item?.text}`)
    item.title === 'email' && window.open(`mailto:${item?.text}`)
    item.title === 'location' &&
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${item?.text}`
      )
  }
  return (
    <Box
      minHeight={{
        md: 1100,
        xs: 'auto',
      }}
      position={'relative'}
      py={8}
    >
      <Grid
        container
        position={'relative'}
        sx={{
          backgroundColor: ContactNoon7Style.background,
          height: {
            md: 800,
            xs: 'auto',
          },
          display: 'flex',
          justifyContent: 'center',
          position: { xs: 'initial', md: 'absolute' },
          top: '50%',
          left: '50%',
          transform: { xs: 'none', md: 'translate(-50%,-50%)' },
          mb: {
            md: 10,
            xs: 1,
          },
          pt: {
            md: 0,
            xs: '30px',
          },
          direction: lang === 'en' ? 'ltr' : 'rtl',
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: { xs: 'relative', md: 'absolute' },
              top: {
                md: '-10%',
                xs: 0,
              },
              left: lang === 'en' ? { xs: 0, md: '1%', lg: '10%' } : undefined,
              right: lang === 'ar' ? { xs: 0, md: '1%', lg: '10%' } : undefined,
              width: { xs: '92.5%', md: '52.5%', lg: '40%' },
              mx: {
                md: 0,
                xs: 'auto',
              },
              zIndex: 100,
            }}
          >
            <ContactForm
              width="100%"
              background="white"
              borderRadiusInput={ContactNoon7Style.borderRadiusInput}
              button={ContactNoon7Style.button}
              color={ContactNoon7Style.color}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: { xs: '90%', md: '80%', lg: '70%' },
              ml: { xs: 0, md: 2 },
            }}
          >
            <Stack
              p={2}
              direction={{ xs: 'column', md: 'row', lg: 'column' }}
              sx={{
                justifyContent: { xs: 'space-between', md: 'flex-start' },
                alignItems: { xs: 'space-between', md: 'flex-start' },
                gap: 2,
                width: '100%',
              }}
            >
              <Box>
                {Connections.map((item, index) => {
                  return (
                    <Stack
                      key={index}
                      direction={'row'}
                      sx={{
                        alignItems: 'center',
                        my: 2,
                        gap: 2,
                        cursor: 'pointer',
                      }}
                      onClick={() => handelConnections(item)}
                    >
                      <Box>
                        <ConnectionsContainer
                          title={item.title}
                          iconColors={ContactNoon7Style.iconColors}
                        />
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: '18px',
                              lg: '20px',
                              xl: '26px',
                            },
                            color: ContactNoon7Style.connectionsDataColor
                              ? ContactNoon7Style.connectionsDataColor
                              : 'black',
                          }}
                        >
                          {item.text}
                        </Typography>
                      </Box>
                    </Stack>
                  )
                })}
              </Box>
              <Box>
                {ourServices.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      my={2}
                      sx={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handelClick(item.link)
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: '18px', md: '23px', xl: '26px' },
                          color: ContactNoon7Style.color?.linkColor
                            ? ContactNoon7Style.color?.linkColor
                            : 'black',
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContactNoon7
