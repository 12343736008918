import { Box, Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
// import Card from '../../../Cards/Scrolls/Scrolling1'
import {
  useGetAllProductsByCategoryQuery,
  useGetMostNewiestProductsQuery,
} from '../../../../redux/apis/ProductApis'
import { ScrollColors } from './colors'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useGetAllBlogsQuery } from '../../../../redux/apis/blogsApi'
import ScrollingCardBlog from '../../../Cards/Scrolls/ScrollingCardBlog'

const ErrorSection = ({ isError, error }) => {
  const [, { language: lang }] = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <Typography
        fontSize={'1.5rem'}
        my={10}
        textAlign={'center'}
        color="error"
      >
        {isError
          ? error?.data && error?.data[`error_${lang}`]
          : error?.data && error?.data[`error_${lang}`]}
      </Typography>
    </Box>
  )
}

const SliderHeader = ({ colors }) => {
  const [, { language: lang }] = useTranslation()
  const navigate = useNavigate()
  return (
    <Box sx={{ textAlign: lang === 'en' ? 'start' : 'end' }}>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: { xs: '2.1rem', sm: '2.1rem', lg: '4rem' },
          color: colors.title,
          textAlign: lang === 'en' ? 'left' : 'right',
        }}
      >
        {lang === 'en' ? 'The blogs' : 'المدونات'}
      </Typography>
      <Button
        variant="outlined"
        sx={{
          mt: 2,
          color: colors.buttonTextColor,
          // borderRadius: props.borderRaduisBtn ? '40px' : '0px',
          padding: { xs: '0.4rem 2rem', lg: '0.6rem 3rem' },
          border: `1px solid ${colors.buttonBorderColor} !important`,
          backgroundColor: colors.buttonBg,
          fontSize: { xs: '20px', md: '1.8rem' },
          borderRadius: '10px',

          height: {
            xs: '40px',
            md: '50px',
          },
          width: 1,
        }}
        onClick={() => {
          navigate(`/blogs`)
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '10px', sm: '15px', md: '18px', lg: '22px' },
            // i need to make words not capital
            textTransform: 'capitalize',
          }}
        >
          {lang === 'en' ? 'View All' : 'عرض الكل'}
        </Typography>
      </Button>
    </Box>
  )
}

const Slider = ({ data, lang }) => {
  return (
    <Grid
      width={'100%'}
      item
      xs={8}
      md={9}
      py={4}
      sx={{
        direction: lang === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Box px={2}>
        <Swiper
          style={{ direction: 'ltr' }}
          className="mySwiper"
          slidesPerView={3}
          spaceBetween={10}
          modules={[FreeMode]}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            460: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            650: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            770: {
              slidesPerView: 2.5,
              spaceBetween: 10,
            },

            900: {
              slidesPerView: 2.3,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 2.5,
              spaceBetween: 10,
            },
            1500: {
              slidesPerView: 3.4,
              spaceBetween: 10,
            },
          }}
        >
          {data?.map((item) => (
            <SwiperSlide key={item.title}>
              <Box
                sx={{
                  height: {
                    xs: '350px',
                    md: '400px',
                    lg: '553px',
                  },
                  width: '100%',
                  'border-radius':'10px',
                  overflow: 'hidden',
                  'box-shadow': 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                  border:'1px solid #ddd'




                }}
              >
                <ScrollingCardBlog data={item} />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Grid>
  )
}

const HomeBlogsSlider = () => {
  const [, { language: lang }] = useTranslation()
  const { colors } = ScrollColors
  const { data, isSuccess, isError, error } = useGetAllBlogsQuery(undefined)

  return (<>
 {!isError &&isSuccess?   <Box
  mt={10}
  sx={{
    direction: lang === 'en' ? 'ltr' : 'rtl',
    bgcolor: colors.bgColor,
  }}
  my={data?.data !== undefined ? 5 : 0}
  py={data?.data !== undefined ? 3 : 0}
>
  {isError && error && <ErrorSection error={error} isError={isError} />}
  {isSuccess && !isError && data?.data?.length > 0 && (
    <Grid container>
      <Grid
        item
        xs={4}
        md={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <SliderHeader colors={colors} />
      </Grid>

      <Slider data={data?.data} lang={lang} />
    </Grid>
  )}
</Box>:null}
</>
  )
}

export default HomeBlogsSlider
