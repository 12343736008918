import {appColors} from "../../../constants/colors"

export const Register2Colors = {
  iconColor:'#A3A3A3',
  backgroundBtn: appColors.secondary.main,
  borderInputColor: '#A3A3A3',
  placeholderColor: appColors.secondary.light,
  color: appColors.secondary.main,
}

