import {appColors} from "../../../constants/colors"

export const CheckOutTomoorColors={
    color:{
        title: '#707070',
        label:'#707070',
      },
      button:{
        color: 'white',
        backgroundColor: appColors.secondary.main,
      },
}