import { Box, Container, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  useGetMostNewiestProductsQuery,
  useGetMostSellingProductsQuery,
} from '../../../../redux/apis/ProductApis.js'
import { collectionColors } from './colors.jsx'
import Card from '../../../Cards/Collections/Collection2/index.jsx'

//⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐ErrorSection⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐
const ErrorSection = ({ error, lang }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
      }}
    >
      <Typography
        fontSize={'1.5rem'}
        my={10}
        textAlign={'center'}
        color="error"
      >
        {error?.data && error?.data[`error_${lang}`]}
      </Typography>
    </Box>
  )
}

const CollectionGrid = ({ data }) => {
  return (
    <Stack
      display={'flex'}
      justifyContent={'center'}
      direction={'row-reverse'}
      gap={{ xs: 0, sm: 3 }}
      sx={{
        width: '100% !important',
      }}
    >
      <Box
        sx={{
          display: 'grid',
        
          width: 1,
        }}
        gridTemplateColumns={{
          xs: 'repeat(2, 1fr)',
          sm: 'repeat(3, 1fr)',
          md: 'repeat(4,1fr)',
        }}
      
        gap={2}
      >
        {data?.map((item, index) => (
          <Box
            gridColumn={{
              xs: 'span 1', 
              md:'span 1', 
            }}
            key={index}
            // minWidth={{ xs: '150px', sm: '130px', md: '200px' }}
            height={{
              md: '95%',
            }}
            sx={{
              borderRadius: '20px',
              overflow: 'hidden',
              border: '1px solid #eee',
              boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
              aspectRatio:"1/1",
            }}
          >
            <Card data={item} />
          </Box>
        ))}
      </Box>
    </Stack>
  )
}
const Collection1 = () => {
  const { data, isSuccess, isError, isLoading, error } =
    useGetMostNewiestProductsQuery(undefined)
  const [, { language: lang }] = useTranslation()
  const slicedData = data?.data?.slice(0, 8)
  const { colors } = collectionColors
  return (
    <Box
      py={slicedData !== undefined ? 3 : 0}
      minHeight={slicedData !== undefined ? '50vh' : 0}
      bgcolor={colors.bgColor}
      width="100% !important"
    >
      {isLoading && <span className="loader"></span>}
      {isError && error && <ErrorSection error={error} lang={lang} />}
      {isSuccess && slicedData?.length > 0 && slicedData !== undefined && (
        <Container
          sx={{
            maxWidth: '1600px !important',
          }}
        >
          <Box
            sx={{
              direction: lang === 'en' ? 'ltr' : 'rtl',
              width: '100% !important',
            }}
          >
            {/* title */}
            <Box mb={5}>
              <Typography
                sx={{
                  fontSize: {
                    xs: '1.8rem',
                    sm: '2.5rem',
                    lg: '3.5rem',
                    xl: '5rem',
                  },
                  fontWeight: 'bold',
                  color: colors.title,
                  mx: { xs: 1, sm: 'auto' },
                  textAlign: { xs: 'initial', sm: 'center' },
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {lang === 'en' ? 'Newest' : ' الاحدث'}
              </Typography>
            </Box>
            {/* collection grid */}
            <CollectionGrid data={slicedData} />
          </Box>
        </Container>
      )}
    </Box>
  )
}
export default Collection1
