import { appColors } from "../../../../../../../constants/colors";

 export const Hero4Style = {
  color: {
    titleColor: '#fff',
    subTitleColor:  '#fff',
  },
  Btn:{
    
      backgroundColorBtn:  '#D50154',
      colorBtn: 'white',
  }
}
