import { appColors } from '../../../../constants/colors'

const { primary, secondary, whiteColor } = appColors
export const CardColors1 = {
  colors: {
    titleColor: secondary.main,
    cardContentBg: whiteColor.main,
    descColor: secondary.main,
    borderColor: 'transparent',
  },
}
