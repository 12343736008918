export const appColors = {
  mainColor: '#Fff',
  rose: '#ffbbbb',
  primary: {
    main: '#28acb3',
    light: '#2b969c',
  },
  secondary: {
    main: '#28acb3',
    light: '#707070',
    dark: 'rgba(0, 0, 0, 0.5)',
  },
  whiteColor: {
    main: '#ffffff',
    dark: 'rgba(0, 0, 0, 0)',
  },
  rose: '#ffbcbc',
}
