export const colors = {
  filter: {
    buttonBg: "#D40054",
    buttonColor: "#fff",
    checkBoxLabel: "greem",
    borderColor: "orangered",
  },
}
export const filterMenuStyles = {
  menuButton: {
    fontSize: {
      md: '20px',
      xs: '15px',
    },
    fontWeight: 'bold',
    mx: '10px',
    color: `${colors.filter.buttonColor} !important`,
  },
  priceBtn: {
    // fontFamily: publicFontFamily,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: colors.filter.checkBoxLabel,
    textAlign: 'center',
  },
  checkBoxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  checkBoxLabel: {
    color: colors.filter.checkBoxLabel,
    fontFamily: 'bold',
    fontSize: '19px',
    cursor: 'pointer',
  },
  formPriceBtn: {
    mt: '10px',
    fontWeight: 'bold',
    fontSize: '13px',
    border: `1px solid ${colors.borderColor}`,
    bgcolor: `${colors.filter.buttonBg} !important`,
    color: `${colors.filter.buttonColor} !important`,
  },
}
