import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Pagination,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  AllProductsGridStyles,
  DepertmentStyles,
  SearchStyles,
} from './subSubCategoryStyle.jsx'
import { CiSearch } from 'react-icons/ci'
import CloseIcon from '@mui/icons-material/Close'
import { useLazyGetAllProductsQuery } from '../../../redux/apis/ProductApis.js'
import { useLazyGetAllAttributesQuery } from '../../../redux/apis/attributeApi.js'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { colors } from './subSubCategoryStyle.jsx'
import { filterMenuStyles } from './subSubCategoryStyle.jsx'
import RefreshIcon from '@mui/icons-material/Refresh'
import MetaTags from '../../../components/metaTags/MetaTags.jsx'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useFetchCategoriesWithSubsWithSubSubCategories } from '../../../layouts/Navbars/buttonStyle/without/navbar.hooks.js'
import Card1 from '../../../components/Cards/Horizontal Rectangle/Card1/index.jsx'
import FilterAttributesMenu from '../../../components/FilterAttributesMenu/FilterAttributesMenu.jsx'
import Card from '../../../components/Cards/Scrolls/Scrolling1/index.jsx'
// ============FilterSearch=========================
const Search = ({ onChange }) => {
  const [search, setSearch] = useState('')
  const location = useLocation()
  useEffect(() => {
    setSearch('')
  }, [location.pathname])
  const {
    i18n: { language },
  } = useTranslation()
  useEffect(() => {
    const id = setTimeout(() => {
      const s = search.trim()
        ? `keyword[title_en]=${search}&keyword[title_ar]=${search}&keyword[description_en]=${search}&keyword[description_ar]=${search}`
        : ''
      onChange(s)
    }, 500)
    return () => {
      clearTimeout(id)
    }
  }, [search])
  return (
    <Box sx={SearchStyles.BoxSearch}>
      <Box
        component={'input'}
        placeholder={language === 'en' ? 'Search' : 'بحث'}
        sx={SearchStyles.BoxInput}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <CiSearch size={24} />
    </Box>
  )
}
// ============FilterSearch=========================

function AllProductsGrid({ cards }) {
  return (
    <Grid
      container
      spacing={AllProductsGridStyles.GridSpacing}
      pt={5}
      // width={'100%'}
      width={{ xs: '97%', sm: '100%', lg: '90%' }}
    >
      {cards?.map((card, index) => (
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          key={index}
          sx={AllProductsGridStyles.CardItem}
        >
          {card}
        </Grid>
      ))}
    </Grid>
  )
}
// ============FilterSearch=========================

// ============================HookCategory====================
const useFetchProductsByCategoryId = (subSubId, subId, query) => {
  const [products, setProducts] = useState([])
  const [paginationData, setPaginationData] = useState({})
  const [params, setSearchParams] = useSearchParams()
  const [getAllProducts, { isLoading }] = useLazyGetAllProductsQuery()
  const pageNumber = params.get('page') || 1
  useEffect(() => {
    if (subSubId && !subId) {
      params.set('subSubCategory', subSubId)
      params.set('limit', 24)
      params.set('page', pageNumber)
      getAllProducts(
        // `brand=${subSubId}&limit=1000${query ? `&${query}` : ''}`
        params.toString().concat(query ? `&${query}` : '')
      )
        .unwrap()
        .then((res) => {
          setPaginationData(res.paginationResult)
          setProducts(res.data)
        })
        .catch(() => {
          setProducts([])
        })
    }
  }, [query, subSubId, subId, pageNumber])
  return {
    categoryProducts: products,
    isLoading,
    paginationData,
    setSearchParams,
    params,
  }
}
// ============================   HookCategory      ====================
export const SubSubCategoryProductsPage = (props) => {
  const { subSubId, categoryItemId, subItemId } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [filterQuery, setFilterQuery] = useState('')
  const [, { language: lng }] = useTranslation()
  //custom hook
  const {
    categoryProducts,
    isLoading: categoryProductsLoading,
    paginationData,
    setSearchParams,
    params,
  } = useFetchProductsByCategoryId(
    subSubId,
    undefined,
    `${filterQuery ? `&${query}` : query}${
      query ? `&${filterQuery}` : filterQuery
    }`
  )
  const Styles = DepertmentStyles({ props, lng })
  const navigate = useNavigate()
  useEffect(() => {
    setData(categoryProducts)
    setLoading(categoryProductsLoading)
  }, [categoryProducts, categoryProductsLoading, subSubId])
  const { totalPages } = paginationData
  const handlPagination = (e, page) => {
    params.set('page', page)
    setSearchParams(params)
  }
  const { categoriesWithSubs } =
    useFetchCategoriesWithSubsWithSubSubCategories()
  let findedCategory = categoriesWithSubs?.data?.find(
    (el) => el?.category?._id == categoryItemId
  )
  let findedSub =
    findedCategory &&
    findedCategory.subCategories.find((el) => el.subCategory._id === subItemId)
  let findedSubSubCategory =
    findedSub &&
    findedSub.subSubCategory.find((subUsb) => subUsb._id === subSubId)

  return (
    <>
      {/* Get Category Meta */}
      <MetaTags id={subSubId} />
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          my: 5,
          px: 3,
          direction: lng === 'en' ? 'ltr' : 'rtl',
          bgcolor: colors.bgcolor,
          wordBreak: 'break-word',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: colors.primary,
            fontSize: {
              lg: '20px',
              xs: '16px',
            },
            ':hover': {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
            display: 'inline-block',
          }}
          onClick={() => navigate('/')}
        >
          {lng === 'en' ? 'Home' : 'الرئسية'}
        </Typography>
        <NavigateNextIcon
          fontSize="small"
          sx={{
            transform: `rotateY(${lng === 'ar' ? '180deg' : '0'})`,
            color: colors.primary,
          }}
        />
        <Typography
          variant="h6"
          sx={{
            cursor: 'pointer',
            color: colors.secondary,
            fontSize: {
              lg: '15px',
              xs: '15px',
            },
            ':hover': {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          }}
          onClick={() => navigate(`/clinics`)}
        >
          {lng === 'en' ? 'Clinics' : 'العيادات'}
        </Typography>
        {findedCategory && (
          <>
            <NavigateNextIcon
              fontSize="small"
              sx={{
                transform: `rotateY(${lng === 'ar' ? '180deg' : '0'})`,
                color: colors.primary,
              }}
            />
            <Typography
              variant="h6"
              sx={{
                cursor: 'pointer',
                color: colors.secondary,
                fontSize: {
                  lg: '15px',
                  xs: '15px',
                },
                ':hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
              onClick={() =>
                navigate(
                  `/clinics/${
                    findedCategory.category._id
                  }/${findedCategory.category.name_en.replace(/\s+/g, '-')}`
                )
              }
            >
              {findedCategory.category[`name_${lng}`]}
            </Typography>
          </>
        )}
        <NavigateNextIcon
          fontSize="small"
          sx={{
            transform: `rotateY(${lng === 'ar' ? '180deg' : '0'})`,
            color: colors.primary,
          }}
        />
        {findedSub && (
          <>
            <Typography
              variant="h6"
              sx={{
                cursor: 'pointer',
                color: colors.secondary,
                fontSize: {
                  lg: '15px',
                  xs: '15px',
                },
                ':hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                navigate(
                  `/clinics/${findedCategory.category._id}/${
                    findedSub?.subCategory._id
                  }/${findedSub?.subCategory.name_en.replace(/\s+/g, '-')}`
                )
              }}
            >
              {findedSub.subCategory[`name_${lng}`]}
            </Typography>
            <NavigateNextIcon
              fontSize="small"
              sx={{
                transform: `rotateY(${lng === 'ar' ? '180deg' : '0'})`,
                color: colors.primary,
              }}
            />
          </>
        )}
        {findedSubSubCategory && (
          <Typography
            variant="h6"
            sx={{
              cursor: 'default',
              color: colors.secondary,
              fontSize: {
                lg: '15px',
                xs: '15px',
              },
            }}
          >
            {findedSubSubCategory[`name_${lng}`]}
          </Typography>
        )}
      </Stack>
      <Box
        sx={{
          ...Styles.Stack,
          alignItems: 'center',
          justifyContent: 'flex-start',
          direction: lng === 'en' ? 'ltr' : 'rtl',
          px: '20px',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
        }}
      >
        {/* <Box width={{xs:1,sm:1,md:0.75}}>
          <Search onChange={(value) => setQuery(value)} />
        </Box> */}
        <FilterAttributesMenu
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
        />
      </Box>
      <Box sx={Styles.Box}>
        <Stack direction={'column'} sx={Styles.StackDirection}>
          {!loading ? (
            <Stack direction={'column'} sx={Styles.AllProductsGridContainer}>
              <AllProductsGrid
                cards={data?.map((pro, index) => (

                  <Box sx={{
                    height: {
                      xs: '350px',
                      md: '400px',
                      lg: '553px'
                    }, width: '100%',
                    'border-radius':'10px',
                    overflow: 'hidden',
                    'border-radius':'10px',
                    overflow: 'hidden', boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    border:'1px solid #ddd'


                  }}>

                  <Card data={pro} key={index} />
                  </Box>
                ))}
              />
              {totalPages > 1 && (
                <Box
                  sx={{
                    direction: 'ltr',
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={Number(params.get('page') || 1)}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlPagination}
                  />
                </Box>
              )}
            </Stack>
          ) : (
            <span className="loader"></span>
          )}
          {!loading && (data?.length < 1 || data?.length === undefined) && (
            <Typography color={'error'} sx={{ m: 5, fontSize: '2rem' }}>
              {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
            </Typography>
          )}
          {/* not fo */}
        </Stack>
      </Box>
    </>
  )
}
