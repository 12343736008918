import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Pagination,
} from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import 'swiper/css'
import { useTranslation } from 'react-i18next'
import { CiSearch } from 'react-icons/ci'
import { useLazyGetAllProductsQuery } from '../../../redux/apis/ProductApis'
import {
  AllProductsGridStyles,
  DepertmentStyles,
  SearchStyles,
} from './subCategories.Styles.jsx'
import Breadcrumbs from '../../../components/BreadCrumbs/BreadCrumbs.jsx'
import { useLazyGetAllAttributesQuery } from '../../../redux/apis/attributeApi.js'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { colors } from './subCategories.Styles.jsx'
import { filterMenuStyles } from './subCategories.Styles.jsx'
import RefreshIcon from '@mui/icons-material/Refresh'
import CloseIcon from '@mui/icons-material/Close'
import MetaTags from '../../../components/metaTags/MetaTags.jsx'
// import Card8 from '../../../components/Cards/Horizontal Rectangle/strockCustomCard7/index.jsx'
import Card from '../../../components/Cards/Scrolls/Scrolling1/index.jsx'
import FilterAttributesMenu from '../../../components/FilterAttributesMenu/FilterAttributesMenu.jsx'

// ========================================search Filter =================
const Search = ({ onChange }) => {
  const [search, setSearch] = useState('')
  const location = useLocation()

  useEffect(() => {
    setSearch('')
  }, [location.pathname])
  const {
    i18n: { language },
  } = useTranslation()
  useEffect(() => {
    const id = setTimeout(() => {
      const s = search.trim()
        ? `keyword[title_en]=${search}&keyword[title_ar]=${search}&keyword[description_en]=${search}&keyword[description_ar]=${search}`
        : ''
      onChange(s)
    }, 500)
    return () => {
      clearTimeout(id)
    }
  }, [search])
  return (
    <Box sx={SearchStyles.BoxSx}>
      <Box
        component={'input'}
        placeholder={language === 'en' ? 'Search' : 'بحث'}
        sx={SearchStyles.BoxInput}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <CiSearch size={24} />
    </Box>
  )
}
// ========================================search Filter =================
// =========================productsGrid=======================================================

function AllProductsGrid({ cards }) {
  return (
    <Grid
      container
      spacing={{
        xs: 2,
        sm: 2,
        md: 4,
        lg: 2,
        xl: 3,
      }}
      pt={1}
      width={{ xs: '97%', sm: '100%', lg: '90%' }}
    >
      {cards?.map((card, index) => (
        <Grid item xs={6} sm={6} md={4} xl={4} key={index}>
          {card}
        </Grid>
      ))}
    </Grid>
  )
}
// =========================productsGrid=======================================================

// ==========================Hook===========================================
const useFetchProductsByCategoryIdAndSubId = (categoryId, subId, query) => {
  const [products, setProducts] = useState([])
  const [params, setSearchParams] = useSearchParams()
  const [paginationData, setPaginationData] = useState({})
  const [getAllProducts, { data, isLoading }] = useLazyGetAllProductsQuery()
  const pageNumber = params.get('page') || 1
  useEffect(() => {
    if (categoryId && subId) {
      params.set('category', categoryId)
      params.set('subCategory', subId)
      params.set('limit', 24)
      params.set('page', pageNumber)
      getAllProducts(
        // `category=${categoryId}&subCategory=${subId}&limit=3&page=${pageNumber}`
        params.toString().concat(query ? `&${query}` : '')
      )
        .unwrap()
        .then((res) => {
          setPaginationData(res.paginationResult)
          setProducts(res.data)
          console.log('datttt', res.data)
        })
        .catch(() => {
          setProducts([])
        })
    }
  }, [data, subId, categoryId, query, pageNumber])
  return {
    categoryAndSubProducts: products,
    isLoading,
    paginationData,
    setSearchParams,
    params,
  }
}
// ==========================Hook===========================================
// ======================================== Main Component=================================

export const SubCategoriePage = () => {
  const { categoryId, subId } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [filterQuery, setFilterQuery] = useState('')
  const [, { language: lng }] = useTranslation()
  const Styles = DepertmentStyles({ lng })
  const {
    categoryAndSubProducts,
    isLoading: categAndSubProductsLoading,
    paginationData,
    setSearchParams,
    params,
  } = useFetchProductsByCategoryIdAndSubId(
    categoryId,
    subId,
    `${filterQuery ? `&${query}` : query}${
      query ? `&${filterQuery}` : filterQuery
    }`
  )
  useEffect(() => {
    setData(categoryAndSubProducts)
    setLoading(categAndSubProductsLoading)
  }, [categoryAndSubProducts, categAndSubProductsLoading, query, subId])
  const { totalPages, page } = paginationData
  const handlPagination = (e, page) => {
    params.set('page', page)
    setSearchParams(params)
  }
  return (
    <>
      <MetaTags id={subId} />
      <Breadcrumbs colors={Styles.Breadcrumbs} />
      <Box
        sx={{
          ...Styles.Stack,
          alignItems: 'center',
          justifyContent: 'flex-start',
          direction: lng === 'en' ? 'ltr' : 'rtl',
          px: {
            md: '20px',
            xs: '5px',
          },
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
        }}
      >
        <FilterAttributesMenu
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
        />
      </Box>
      <Box sx={Styles.Box}>
        <Stack direction={'column'} sx={Styles.StackDirection}>
          {!categAndSubProductsLoading ? (
            <Stack direction={'column'} sx={Styles.AllProductsGridContainer}>
              <AllProductsGrid
                cards={data?.map((pro, index) => (
                  <Box
                    className="card_category Two_column"
                    sx={{
                      '& button': {
                        padding: {
                          xs: '13px 30px !important',
                          md: '11px 30px !important',
                        },
                      },
                      height: {
                        xs: '350px',
                        md: '400px',
                        lg: '553px'
                      }, width: '100%',
                      'border-radius':'10px',
                      overflow: 'hidden',
                      border:'1px solid #ddd' ,boxShadow:"rgba(149, 157, 165, 0.2) 0px 8px 24px",


                    }}
                  >
                    <Card data={pro} />
                  </Box>
                ))}
              />

              {totalPages > 1 && (
                <Box
                  sx={{
                    direction: 'ltr',
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={Number(params.get('page' || 1))}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlPagination}
                    sx={{
                      '& button': {
                        width: '32px',
                        height: '32px',
                      },
                    }}
                  />
                </Box>
              )}
            </Stack>
          ) : (
            <span className="loader"></span>
          )}
          {!loading && (data?.length < 1 || data?.length === undefined) && (
            <Typography color={'error'} sx={{ m: 5, fontSize: '2rem' }}>
              {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
            </Typography>
          )}
          {/* not fo */}
        </Stack>
      </Box>
    </>
  )
}
// ======================================== Main Component=================================

