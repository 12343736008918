// import {appColors} from "../../constants/colors"

import { appColors } from "../../../constants/colors";


export const colors = {
  header: appColors?.secondary?.main,
  title: appColors?.secondary?.main,
  remove: '#707070',
  productsBg: '#f1f7f6',
  priceBefore: appColors?.secondary?.main,
  priceAfter: appColors?.secondary?.main,
  button: 'white',
  buttonBg: appColors?.secondary?.main,
  orderSummary: '#707070',
  orderSummaryBg: '#f1f7f6',
  orderSummaryBody: '#544e5e',
  quantity: appColors?.secondary?.main,
  desc: appColors?.secondary?.main,
}
